export enum ScoreType {
  AMRAP = 'AMRAP',
  REPS = 'REPS',
  TIME = 'TIME',
  ENDURANCE = 'ENDURANCE',
  WEIGHT = 'WEIGHT',
  NUMBER = 'NUMBER',
}

export type AMRAP_SCORE = {
  type: ScoreType.AMRAP
  rounds: number
  reps: number
}

export type REPS_SCORE = {
  type: ScoreType.REPS
  reps: number
}

export type TIME_SCORE = {
  type: ScoreType.TIME
  hours: number
  minutes: number
  seconds: number
  /*
  - Optional because doesn't mean there weren't any just none recorded
  - Max 2 digits gets used for scoreRank
   */
  milliseconds?: number
}

export type ENDURANCE_SCORE = {
  type: ScoreType.ENDURANCE
  hours: number
  minutes: number
  seconds: number
  /*
  - Optional because doesn't mean there weren't any just none recorded
  - Max 2 digits gets used for scoreRank
   */
  milliseconds?: number
}

export type WEIGHT_SCORE = {
  type: ScoreType.WEIGHT
  weight: number // All weights recorded in kgs
}

/**
 * Arbitrary score system for anything like
 * distance, calories, number of crates etc
 */
export type NUMBER_SCORE = {
  type: ScoreType.NUMBER
  score: number
  bigLabel?: string
  littleLabel?: string
}

export type ScoreEntry =
  | AMRAP_SCORE
  | REPS_SCORE
  | TIME_SCORE
  | ENDURANCE_SCORE
  | WEIGHT_SCORE
  | NUMBER_SCORE

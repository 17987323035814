import {
  ApolloClient,
  ApolloProvider as ApolloProviderInternal,
  InMemoryCache,
} from '@apollo/client'
import { useSession } from 'next-auth/react'
import { FC, useMemo } from 'react'

export const ApolloProvider: FC = ({ children }) => {
  const session = useSession()

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: `${process.env.API_URL}/v1/graphql`,
        cache: new InMemoryCache(),
        headers: session?.data?.token ? { Authorization: `Bearer ${session.data.token}` } : {},
      }),
    [session.data?.oauth_id],
  )

  return <ApolloProviderInternal client={client}>{children}</ApolloProviderInternal>
}

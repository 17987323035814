import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  jsonb: any
  timestamp: string
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>
  _gt?: Maybe<Scalars['Boolean']>
  _gte?: Maybe<Scalars['Boolean']>
  _in?: Maybe<Array<Scalars['Boolean']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Boolean']>
  _lte?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Scalars['Boolean']>
  _nin?: Maybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>
  _gt?: Maybe<Scalars['Int']>
  _gte?: Maybe<Scalars['Int']>
  _in?: Maybe<Array<Scalars['Int']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Int']>
  _lte?: Maybe<Scalars['Int']>
  _neq?: Maybe<Scalars['Int']>
  _nin?: Maybe<Array<Scalars['Int']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>
  _gt?: Maybe<Scalars['String']>
  _gte?: Maybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>
  _in?: Maybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>
  _is_null?: Maybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>
  _lt?: Maybe<Scalars['String']>
  _lte?: Maybe<Scalars['String']>
  _neq?: Maybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>
  _nin?: Maybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>
}

/**
 * A workaround to allow us to return booleans from functions.
 *
 *
 * columns and relationships of "boolean"
 */
export type Boolean = {
  __typename?: 'boolean'
  boolean: Scalars['Boolean']
}

/** aggregated selection of "boolean" */
export type Boolean_Aggregate = {
  __typename?: 'boolean_aggregate'
  aggregate?: Maybe<Boolean_Aggregate_Fields>
  nodes: Array<Boolean>
}

/** aggregate fields of "boolean" */
export type Boolean_Aggregate_Fields = {
  __typename?: 'boolean_aggregate_fields'
  count: Scalars['Int']
}

/** aggregate fields of "boolean" */
export type Boolean_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Boolean_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "boolean". All fields are combined with a logical 'AND'. */
export type Boolean_Bool_Exp = {
  _and?: Maybe<Array<Boolean_Bool_Exp>>
  _not?: Maybe<Boolean_Bool_Exp>
  _or?: Maybe<Array<Boolean_Bool_Exp>>
  boolean?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "boolean" */
export enum Boolean_Constraint {
  /** unique or primary key constraint */
  BooleanPkey = 'boolean_pkey',
}

/** input type for inserting data into table "boolean" */
export type Boolean_Insert_Input = {
  boolean?: Maybe<Scalars['Boolean']>
}

/** response of any mutation on the table "boolean" */
export type Boolean_Mutation_Response = {
  __typename?: 'boolean_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Boolean>
}

/** on conflict condition type for table "boolean" */
export type Boolean_On_Conflict = {
  constraint: Boolean_Constraint
  update_columns?: Array<Boolean_Update_Column>
  where?: Maybe<Boolean_Bool_Exp>
}

/** Ordering options when selecting data from "boolean". */
export type Boolean_Order_By = {
  boolean?: Maybe<Order_By>
}

/** primary key columns input for table: boolean */
export type Boolean_Pk_Columns_Input = {
  boolean: Scalars['Boolean']
}

/** select columns of table "boolean" */
export enum Boolean_Select_Column {
  /** column name */
  Boolean = 'boolean',
}

/** input type for updating data in table "boolean" */
export type Boolean_Set_Input = {
  boolean?: Maybe<Scalars['Boolean']>
}

/** update columns of table "boolean" */
export enum Boolean_Update_Column {
  /** column name */
  Boolean = 'boolean',
}

/** columns and relationships of "challenge" */
export type Challenge = {
  __typename?: 'challenge'
  /** An object relationship */
  activeState: Challenge_Active_State_Enum
  active_state: Challenge_Active_State_Enum_Enum
  /** An array relationship */
  challengeEntries: Array<Challenge_Entry>
  /** An aggregate relationship */
  challengeEntries_aggregate: Challenge_Entry_Aggregate
  /** An object relationship */
  challengeGroup?: Maybe<Challenge_Group>
  challengeGroupId: Scalars['uuid']
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  title: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "challenge" */
export type ChallengeChallengeEntriesArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

/** columns and relationships of "challenge" */
export type ChallengeChallengeEntries_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

/** columns and relationships of "challenge_active_state_enum" */
export type Challenge_Active_State_Enum = {
  __typename?: 'challenge_active_state_enum'
  description?: Maybe<Scalars['String']>
  state: Scalars['String']
}

/** aggregated selection of "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Aggregate = {
  __typename?: 'challenge_active_state_enum_aggregate'
  aggregate?: Maybe<Challenge_Active_State_Enum_Aggregate_Fields>
  nodes: Array<Challenge_Active_State_Enum>
}

/** aggregate fields of "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Aggregate_Fields = {
  __typename?: 'challenge_active_state_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Active_State_Enum_Max_Fields>
  min?: Maybe<Challenge_Active_State_Enum_Min_Fields>
}

/** aggregate fields of "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Active_State_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "challenge_active_state_enum". All fields are combined with a logical 'AND'. */
export type Challenge_Active_State_Enum_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Active_State_Enum_Bool_Exp>>
  _not?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
  _or?: Maybe<Array<Challenge_Active_State_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  state?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_active_state_enum" */
export enum Challenge_Active_State_Enum_Constraint {
  /** unique or primary key constraint */
  ChallengeActiveStateEnumPkey = 'challenge_active_state_enum_pkey',
}

export enum Challenge_Active_State_Enum_Enum {
  /** Is currently in the process of being created */
  Creating = 'CREATING',
  /** Has been deleted */
  Deleted = 'DELETED',
  /** Is currently live (note: not using "active" as that may depend on start / end times) */
  Visible = 'VISIBLE',
}

/** Boolean expression to compare columns of type "challenge_active_state_enum_enum". All fields are combined with logical 'AND'. */
export type Challenge_Active_State_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<Challenge_Active_State_Enum_Enum>
  _in?: Maybe<Array<Challenge_Active_State_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Challenge_Active_State_Enum_Enum>
  _nin?: Maybe<Array<Challenge_Active_State_Enum_Enum>>
}

/** input type for inserting data into table "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Challenge_Active_State_Enum_Max_Fields = {
  __typename?: 'challenge_active_state_enum_max_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Challenge_Active_State_Enum_Min_Fields = {
  __typename?: 'challenge_active_state_enum_min_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Mutation_Response = {
  __typename?: 'challenge_active_state_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Active_State_Enum>
}

/** input type for inserting object relation for remote table "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Obj_Rel_Insert_Input = {
  data: Challenge_Active_State_Enum_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Active_State_Enum_On_Conflict>
}

/** on conflict condition type for table "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_On_Conflict = {
  constraint: Challenge_Active_State_Enum_Constraint
  update_columns?: Array<Challenge_Active_State_Enum_Update_Column>
  where?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_active_state_enum". */
export type Challenge_Active_State_Enum_Order_By = {
  description?: Maybe<Order_By>
  state?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_active_state_enum */
export type Challenge_Active_State_Enum_Pk_Columns_Input = {
  state: Scalars['String']
}

/** select columns of table "challenge_active_state_enum" */
export enum Challenge_Active_State_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "challenge_active_state_enum" */
export type Challenge_Active_State_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** update columns of table "challenge_active_state_enum" */
export enum Challenge_Active_State_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** aggregated selection of "challenge" */
export type Challenge_Aggregate = {
  __typename?: 'challenge_aggregate'
  aggregate?: Maybe<Challenge_Aggregate_Fields>
  nodes: Array<Challenge>
}

/** aggregate fields of "challenge" */
export type Challenge_Aggregate_Fields = {
  __typename?: 'challenge_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Max_Fields>
  min?: Maybe<Challenge_Min_Fields>
}

/** aggregate fields of "challenge" */
export type Challenge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge" */
export type Challenge_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Max_Order_By>
  min?: Maybe<Challenge_Min_Order_By>
}

/** input type for inserting array relation for remote table "challenge" */
export type Challenge_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_On_Conflict>
}

/** Boolean expression to filter rows from the table "challenge". All fields are combined with a logical 'AND'. */
export type Challenge_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Bool_Exp>>
  _not?: Maybe<Challenge_Bool_Exp>
  _or?: Maybe<Array<Challenge_Bool_Exp>>
  activeState?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
  active_state?: Maybe<Challenge_Active_State_Enum_Enum_Comparison_Exp>
  challengeEntries?: Maybe<Challenge_Entry_Bool_Exp>
  challengeGroup?: Maybe<Challenge_Group_Bool_Exp>
  challengeGroupId?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge" */
export enum Challenge_Constraint {
  /** unique or primary key constraint */
  CompetitionPkey = 'competition_pkey',
}

/** columns and relationships of "challenge_entry" */
export type Challenge_Entry = {
  __typename?: 'challenge_entry'
  /** An object relationship */
  activeState: Challenge_Entry_Active_State_Enum
  active_state: Challenge_Entry_Active_State_Enum_Enum
  anonUsername?: Maybe<Scalars['String']>
  /** An object relationship */
  challenge: Challenge
  challengeId: Scalars['uuid']
  comment?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  entryComments: Array<Challenge_Entry_Comment>
  /** An aggregate relationship */
  entryComments_aggregate: Challenge_Entry_Comment_Aggregate
  id: Scalars['uuid']
  score: Scalars['jsonb']
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank: Scalars['Int']
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user?: Maybe<User>
  userId?: Maybe<Scalars['uuid']>
}

/** columns and relationships of "challenge_entry" */
export type Challenge_EntryEntryCommentsArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

/** columns and relationships of "challenge_entry" */
export type Challenge_EntryEntryComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

/** columns and relationships of "challenge_entry" */
export type Challenge_EntryScoreArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum = {
  __typename?: 'challenge_entry_active_state_enum'
  description?: Maybe<Scalars['String']>
  state: Scalars['String']
}

/** aggregated selection of "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Aggregate = {
  __typename?: 'challenge_entry_active_state_enum_aggregate'
  aggregate?: Maybe<Challenge_Entry_Active_State_Enum_Aggregate_Fields>
  nodes: Array<Challenge_Entry_Active_State_Enum>
}

/** aggregate fields of "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Aggregate_Fields = {
  __typename?: 'challenge_entry_active_state_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Entry_Active_State_Enum_Max_Fields>
  min?: Maybe<Challenge_Entry_Active_State_Enum_Min_Fields>
}

/** aggregate fields of "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Entry_Active_State_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "challenge_entry_active_state_enum". All fields are combined with a logical 'AND'. */
export type Challenge_Entry_Active_State_Enum_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Entry_Active_State_Enum_Bool_Exp>>
  _not?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
  _or?: Maybe<Array<Challenge_Entry_Active_State_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  state?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_entry_active_state_enum" */
export enum Challenge_Entry_Active_State_Enum_Constraint {
  /** unique or primary key constraint */
  ChallengeEntryActiveStateEnumPkey = 'challenge_entry_active_state_enum_pkey',
}

export enum Challenge_Entry_Active_State_Enum_Enum {
  /** Live and visible on leaderboard */
  Active = 'ACTIVE',
  /** Entry deleted by creator of challenge */
  DeletedCreator = 'DELETED_CREATOR',
  /** Entry deleted by user that entered score */
  DeletedUser = 'DELETED_USER',
}

/** Boolean expression to compare columns of type "challenge_entry_active_state_enum_enum". All fields are combined with logical 'AND'. */
export type Challenge_Entry_Active_State_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<Challenge_Entry_Active_State_Enum_Enum>
  _in?: Maybe<Array<Challenge_Entry_Active_State_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Challenge_Entry_Active_State_Enum_Enum>
  _nin?: Maybe<Array<Challenge_Entry_Active_State_Enum_Enum>>
}

/** input type for inserting data into table "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Challenge_Entry_Active_State_Enum_Max_Fields = {
  __typename?: 'challenge_entry_active_state_enum_max_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Challenge_Entry_Active_State_Enum_Min_Fields = {
  __typename?: 'challenge_entry_active_state_enum_min_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Mutation_Response = {
  __typename?: 'challenge_entry_active_state_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Entry_Active_State_Enum>
}

/** input type for inserting object relation for remote table "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Obj_Rel_Insert_Input = {
  data: Challenge_Entry_Active_State_Enum_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Entry_Active_State_Enum_On_Conflict>
}

/** on conflict condition type for table "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_On_Conflict = {
  constraint: Challenge_Entry_Active_State_Enum_Constraint
  update_columns?: Array<Challenge_Entry_Active_State_Enum_Update_Column>
  where?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_entry_active_state_enum". */
export type Challenge_Entry_Active_State_Enum_Order_By = {
  description?: Maybe<Order_By>
  state?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_entry_active_state_enum */
export type Challenge_Entry_Active_State_Enum_Pk_Columns_Input = {
  state: Scalars['String']
}

/** select columns of table "challenge_entry_active_state_enum" */
export enum Challenge_Entry_Active_State_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "challenge_entry_active_state_enum" */
export type Challenge_Entry_Active_State_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** update columns of table "challenge_entry_active_state_enum" */
export enum Challenge_Entry_Active_State_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** aggregated selection of "challenge_entry" */
export type Challenge_Entry_Aggregate = {
  __typename?: 'challenge_entry_aggregate'
  aggregate?: Maybe<Challenge_Entry_Aggregate_Fields>
  nodes: Array<Challenge_Entry>
}

/** aggregate fields of "challenge_entry" */
export type Challenge_Entry_Aggregate_Fields = {
  __typename?: 'challenge_entry_aggregate_fields'
  avg?: Maybe<Challenge_Entry_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Challenge_Entry_Max_Fields>
  min?: Maybe<Challenge_Entry_Min_Fields>
  stddev?: Maybe<Challenge_Entry_Stddev_Fields>
  stddev_pop?: Maybe<Challenge_Entry_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Challenge_Entry_Stddev_Samp_Fields>
  sum?: Maybe<Challenge_Entry_Sum_Fields>
  var_pop?: Maybe<Challenge_Entry_Var_Pop_Fields>
  var_samp?: Maybe<Challenge_Entry_Var_Samp_Fields>
  variance?: Maybe<Challenge_Entry_Variance_Fields>
}

/** aggregate fields of "challenge_entry" */
export type Challenge_Entry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Entry_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge_entry" */
export type Challenge_Entry_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Entry_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Entry_Max_Order_By>
  min?: Maybe<Challenge_Entry_Min_Order_By>
  stddev?: Maybe<Challenge_Entry_Stddev_Order_By>
  stddev_pop?: Maybe<Challenge_Entry_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Challenge_Entry_Stddev_Samp_Order_By>
  sum?: Maybe<Challenge_Entry_Sum_Order_By>
  var_pop?: Maybe<Challenge_Entry_Var_Pop_Order_By>
  var_samp?: Maybe<Challenge_Entry_Var_Samp_Order_By>
  variance?: Maybe<Challenge_Entry_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Challenge_Entry_Append_Input = {
  score?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "challenge_entry" */
export type Challenge_Entry_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Entry_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Entry_On_Conflict>
}

/** aggregate avg on columns */
export type Challenge_Entry_Avg_Fields = {
  __typename?: 'challenge_entry_avg_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "challenge_entry" */
export type Challenge_Entry_Avg_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "challenge_entry". All fields are combined with a logical 'AND'. */
export type Challenge_Entry_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Entry_Bool_Exp>>
  _not?: Maybe<Challenge_Entry_Bool_Exp>
  _or?: Maybe<Array<Challenge_Entry_Bool_Exp>>
  activeState?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
  active_state?: Maybe<Challenge_Entry_Active_State_Enum_Enum_Comparison_Exp>
  anonUsername?: Maybe<String_Comparison_Exp>
  challenge?: Maybe<Challenge_Bool_Exp>
  challengeId?: Maybe<Uuid_Comparison_Exp>
  comment?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  entryComments?: Maybe<Challenge_Entry_Comment_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  score?: Maybe<Jsonb_Comparison_Exp>
  scoreRank?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  userId?: Maybe<Uuid_Comparison_Exp>
}

/** columns and relationships of "challenge_entry_comment" */
export type Challenge_Entry_Comment = {
  __typename?: 'challenge_entry_comment'
  /** An object relationship */
  challengeEntry: Challenge_Entry
  created_at: Scalars['timestamptz']
  entry_id: Scalars['uuid']
  id: Scalars['uuid']
  message: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']
}

/** aggregated selection of "challenge_entry_comment" */
export type Challenge_Entry_Comment_Aggregate = {
  __typename?: 'challenge_entry_comment_aggregate'
  aggregate?: Maybe<Challenge_Entry_Comment_Aggregate_Fields>
  nodes: Array<Challenge_Entry_Comment>
}

/** aggregate fields of "challenge_entry_comment" */
export type Challenge_Entry_Comment_Aggregate_Fields = {
  __typename?: 'challenge_entry_comment_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Entry_Comment_Max_Fields>
  min?: Maybe<Challenge_Entry_Comment_Min_Fields>
}

/** aggregate fields of "challenge_entry_comment" */
export type Challenge_Entry_Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Entry_Comment_Max_Order_By>
  min?: Maybe<Challenge_Entry_Comment_Min_Order_By>
}

/** input type for inserting array relation for remote table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Entry_Comment_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Entry_Comment_On_Conflict>
}

/** Boolean expression to filter rows from the table "challenge_entry_comment". All fields are combined with a logical 'AND'. */
export type Challenge_Entry_Comment_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Entry_Comment_Bool_Exp>>
  _not?: Maybe<Challenge_Entry_Comment_Bool_Exp>
  _or?: Maybe<Array<Challenge_Entry_Comment_Bool_Exp>>
  challengeEntry?: Maybe<Challenge_Entry_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  entry_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  message?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_entry_comment" */
export enum Challenge_Entry_Comment_Constraint {
  /** unique or primary key constraint */
  ChallengeEntryCommentPkey = 'challenge_entry_comment_pkey',
}

/** input type for inserting data into table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Insert_Input = {
  challengeEntry?: Maybe<Challenge_Entry_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  entry_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Challenge_Entry_Comment_Max_Fields = {
  __typename?: 'challenge_entry_comment_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  entry_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Max_Order_By = {
  created_at?: Maybe<Order_By>
  entry_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  message?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Entry_Comment_Min_Fields = {
  __typename?: 'challenge_entry_comment_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  entry_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Min_Order_By = {
  created_at?: Maybe<Order_By>
  entry_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  message?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Mutation_Response = {
  __typename?: 'challenge_entry_comment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Entry_Comment>
}

/** on conflict condition type for table "challenge_entry_comment" */
export type Challenge_Entry_Comment_On_Conflict = {
  constraint: Challenge_Entry_Comment_Constraint
  update_columns?: Array<Challenge_Entry_Comment_Update_Column>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_entry_comment". */
export type Challenge_Entry_Comment_Order_By = {
  challengeEntry?: Maybe<Challenge_Entry_Order_By>
  created_at?: Maybe<Order_By>
  entry_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  message?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_entry_comment */
export type Challenge_Entry_Comment_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "challenge_entry_comment" */
export enum Challenge_Entry_Comment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryId = 'entry_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "challenge_entry_comment" */
export type Challenge_Entry_Comment_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  entry_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** update columns of table "challenge_entry_comment" */
export enum Challenge_Entry_Comment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryId = 'entry_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** unique or primary key constraints on table "challenge_entry" */
export enum Challenge_Entry_Constraint {
  /** unique or primary key constraint */
  CompetitionEntryPkey = 'competition_entry_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Challenge_Entry_Delete_At_Path_Input = {
  score?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Challenge_Entry_Delete_Elem_Input = {
  score?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Challenge_Entry_Delete_Key_Input = {
  score?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "challenge_entry" */
export type Challenge_Entry_Inc_Input = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "challenge_entry" */
export type Challenge_Entry_Insert_Input = {
  activeState?: Maybe<Challenge_Entry_Active_State_Enum_Obj_Rel_Insert_Input>
  active_state?: Maybe<Challenge_Entry_Active_State_Enum_Enum>
  anonUsername?: Maybe<Scalars['String']>
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>
  challengeId?: Maybe<Scalars['uuid']>
  comment?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  entryComments?: Maybe<Challenge_Entry_Comment_Arr_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  score?: Maybe<Scalars['jsonb']>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  userId?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Challenge_Entry_Max_Fields = {
  __typename?: 'challenge_entry_max_fields'
  anonUsername?: Maybe<Scalars['String']>
  challengeId?: Maybe<Scalars['uuid']>
  comment?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "challenge_entry" */
export type Challenge_Entry_Max_Order_By = {
  anonUsername?: Maybe<Order_By>
  challengeId?: Maybe<Order_By>
  comment?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Entry_Min_Fields = {
  __typename?: 'challenge_entry_min_fields'
  anonUsername?: Maybe<Scalars['String']>
  challengeId?: Maybe<Scalars['uuid']>
  comment?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "challenge_entry" */
export type Challenge_Entry_Min_Order_By = {
  anonUsername?: Maybe<Order_By>
  challengeId?: Maybe<Order_By>
  comment?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge_entry" */
export type Challenge_Entry_Mutation_Response = {
  __typename?: 'challenge_entry_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Entry>
}

/** input type for inserting object relation for remote table "challenge_entry" */
export type Challenge_Entry_Obj_Rel_Insert_Input = {
  data: Challenge_Entry_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Entry_On_Conflict>
}

/** on conflict condition type for table "challenge_entry" */
export type Challenge_Entry_On_Conflict = {
  constraint: Challenge_Entry_Constraint
  update_columns?: Array<Challenge_Entry_Update_Column>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_entry". */
export type Challenge_Entry_Order_By = {
  activeState?: Maybe<Challenge_Entry_Active_State_Enum_Order_By>
  active_state?: Maybe<Order_By>
  anonUsername?: Maybe<Order_By>
  challenge?: Maybe<Challenge_Order_By>
  challengeId?: Maybe<Order_By>
  comment?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  entryComments_aggregate?: Maybe<Challenge_Entry_Comment_Aggregate_Order_By>
  id?: Maybe<Order_By>
  score?: Maybe<Order_By>
  scoreRank?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  userId?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_entry */
export type Challenge_Entry_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Challenge_Entry_Prepend_Input = {
  score?: Maybe<Scalars['jsonb']>
}

/** select columns of table "challenge_entry" */
export enum Challenge_Entry_Select_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  AnonUsername = 'anonUsername',
  /** column name */
  ChallengeId = 'challengeId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  ScoreRank = 'scoreRank',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "challenge_entry" */
export type Challenge_Entry_Set_Input = {
  active_state?: Maybe<Challenge_Entry_Active_State_Enum_Enum>
  anonUsername?: Maybe<Scalars['String']>
  challengeId?: Maybe<Scalars['uuid']>
  comment?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  score?: Maybe<Scalars['jsonb']>
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** aggregate stddev on columns */
export type Challenge_Entry_Stddev_Fields = {
  __typename?: 'challenge_entry_stddev_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "challenge_entry" */
export type Challenge_Entry_Stddev_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Challenge_Entry_Stddev_Pop_Fields = {
  __typename?: 'challenge_entry_stddev_pop_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "challenge_entry" */
export type Challenge_Entry_Stddev_Pop_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Challenge_Entry_Stddev_Samp_Fields = {
  __typename?: 'challenge_entry_stddev_samp_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "challenge_entry" */
export type Challenge_Entry_Stddev_Samp_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** aggregate sum on columns */
export type Challenge_Entry_Sum_Fields = {
  __typename?: 'challenge_entry_sum_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "challenge_entry" */
export type Challenge_Entry_Sum_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** update columns of table "challenge_entry" */
export enum Challenge_Entry_Update_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  AnonUsername = 'anonUsername',
  /** column name */
  ChallengeId = 'challengeId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  ScoreRank = 'scoreRank',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/** aggregate var_pop on columns */
export type Challenge_Entry_Var_Pop_Fields = {
  __typename?: 'challenge_entry_var_pop_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "challenge_entry" */
export type Challenge_Entry_Var_Pop_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Challenge_Entry_Var_Samp_Fields = {
  __typename?: 'challenge_entry_var_samp_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "challenge_entry" */
export type Challenge_Entry_Var_Samp_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Challenge_Entry_Variance_Fields = {
  __typename?: 'challenge_entry_variance_fields'
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "challenge_entry" */
export type Challenge_Entry_Variance_Order_By = {
  /** A field that is used for ranking scores against each other. Will be different for each scoring system (AMRAP, Time etc). Higher is always better */
  scoreRank?: Maybe<Order_By>
}

/** columns and relationships of "challenge_follow" */
export type Challenge_Follow = {
  __typename?: 'challenge_follow'
  /** An object relationship */
  challengeGroup: Challenge_Group
  challengeGroupId: Scalars['uuid']
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: User
  userId: Scalars['uuid']
}

/** aggregated selection of "challenge_follow" */
export type Challenge_Follow_Aggregate = {
  __typename?: 'challenge_follow_aggregate'
  aggregate?: Maybe<Challenge_Follow_Aggregate_Fields>
  nodes: Array<Challenge_Follow>
}

/** aggregate fields of "challenge_follow" */
export type Challenge_Follow_Aggregate_Fields = {
  __typename?: 'challenge_follow_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Follow_Max_Fields>
  min?: Maybe<Challenge_Follow_Min_Fields>
}

/** aggregate fields of "challenge_follow" */
export type Challenge_Follow_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Follow_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge_follow" */
export type Challenge_Follow_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Follow_Max_Order_By>
  min?: Maybe<Challenge_Follow_Min_Order_By>
}

/** input type for inserting array relation for remote table "challenge_follow" */
export type Challenge_Follow_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Follow_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Follow_On_Conflict>
}

/** Boolean expression to filter rows from the table "challenge_follow". All fields are combined with a logical 'AND'. */
export type Challenge_Follow_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Follow_Bool_Exp>>
  _not?: Maybe<Challenge_Follow_Bool_Exp>
  _or?: Maybe<Array<Challenge_Follow_Bool_Exp>>
  challengeGroup?: Maybe<Challenge_Group_Bool_Exp>
  challengeGroupId?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  userId?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_follow" */
export enum Challenge_Follow_Constraint {
  /** unique or primary key constraint */
  CompetitionFollowPkey = 'competition_follow_pkey',
}

/** input type for inserting data into table "challenge_follow" */
export type Challenge_Follow_Insert_Input = {
  challengeGroup?: Maybe<Challenge_Group_Obj_Rel_Insert_Input>
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  userId?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Challenge_Follow_Max_Fields = {
  __typename?: 'challenge_follow_max_fields'
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "challenge_follow" */
export type Challenge_Follow_Max_Order_By = {
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Follow_Min_Fields = {
  __typename?: 'challenge_follow_min_fields'
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "challenge_follow" */
export type Challenge_Follow_Min_Order_By = {
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge_follow" */
export type Challenge_Follow_Mutation_Response = {
  __typename?: 'challenge_follow_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Follow>
}

/** on conflict condition type for table "challenge_follow" */
export type Challenge_Follow_On_Conflict = {
  constraint: Challenge_Follow_Constraint
  update_columns?: Array<Challenge_Follow_Update_Column>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_follow". */
export type Challenge_Follow_Order_By = {
  challengeGroup?: Maybe<Challenge_Group_Order_By>
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  userId?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_follow */
export type Challenge_Follow_Pk_Columns_Input = {
  challengeGroupId: Scalars['uuid']
  userId: Scalars['uuid']
}

/** select columns of table "challenge_follow" */
export enum Challenge_Follow_Select_Column {
  /** column name */
  ChallengeGroupId = 'challengeGroupId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "challenge_follow" */
export type Challenge_Follow_Set_Input = {
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** update columns of table "challenge_follow" */
export enum Challenge_Follow_Update_Column {
  /** column name */
  ChallengeGroupId = 'challengeGroupId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/** columns and relationships of "challenge_group" */
export type Challenge_Group = {
  __typename?: 'challenge_group'
  /** An object relationship */
  activeState: Challenge_Group_Active_State_Enum
  active_state: Challenge_Group_Active_State_Enum_Enum
  /** An array relationship */
  challenges: Array<Challenge>
  /** An aggregate relationship */
  challenges_aggregate: Challenge_Aggregate
  created_at?: Maybe<Scalars['timestamptz']>
  description: Scalars['String']
  endDate: Scalars['timestamp']
  /** An array relationship */
  followers: Array<Challenge_Follow>
  /** An aggregate relationship */
  followers_aggregate: Challenge_Follow_Aggregate
  /** An object relationship */
  frequency: Challenge_Group_Series_Frequency
  id: Scalars['uuid']
  /** Type of score that people are allowed to enter */
  scoreType: Scalars['String']
  startDate: Scalars['timestamp']
  title: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: User
  userId: Scalars['uuid']
}

/** columns and relationships of "challenge_group" */
export type Challenge_GroupChallengesArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

/** columns and relationships of "challenge_group" */
export type Challenge_GroupChallenges_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

/** columns and relationships of "challenge_group" */
export type Challenge_GroupFollowersArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

/** columns and relationships of "challenge_group" */
export type Challenge_GroupFollowers_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

/** columns and relationships of "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum = {
  __typename?: 'challenge_group_active_state_enum'
  description?: Maybe<Scalars['String']>
  state: Scalars['String']
}

/** aggregated selection of "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Aggregate = {
  __typename?: 'challenge_group_active_state_enum_aggregate'
  aggregate?: Maybe<Challenge_Group_Active_State_Enum_Aggregate_Fields>
  nodes: Array<Challenge_Group_Active_State_Enum>
}

/** aggregate fields of "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Aggregate_Fields = {
  __typename?: 'challenge_group_active_state_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Group_Active_State_Enum_Max_Fields>
  min?: Maybe<Challenge_Group_Active_State_Enum_Min_Fields>
}

/** aggregate fields of "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Group_Active_State_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "challenge_group_active_state_enum". All fields are combined with a logical 'AND'. */
export type Challenge_Group_Active_State_Enum_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Group_Active_State_Enum_Bool_Exp>>
  _not?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
  _or?: Maybe<Array<Challenge_Group_Active_State_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  state?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_group_active_state_enum" */
export enum Challenge_Group_Active_State_Enum_Constraint {
  /** unique or primary key constraint */
  ChallengeGroupActiveStateEnumPkey = 'challenge_group_active_state_enum_pkey',
}

export enum Challenge_Group_Active_State_Enum_Enum {
  /** Is currently in the process of being created */
  Creating = 'CREATING',
  /** Group has been deleted */
  Deleted = 'DELETED',
  /** Has not been deleted but is not currently visible */
  Hidden = 'HIDDEN',
  /** Group is currently live (note: the start & end date with respect to current date will dictate if it is "active") */
  Visible = 'VISIBLE',
}

/** Boolean expression to compare columns of type "challenge_group_active_state_enum_enum". All fields are combined with logical 'AND'. */
export type Challenge_Group_Active_State_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<Challenge_Group_Active_State_Enum_Enum>
  _in?: Maybe<Array<Challenge_Group_Active_State_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Challenge_Group_Active_State_Enum_Enum>
  _nin?: Maybe<Array<Challenge_Group_Active_State_Enum_Enum>>
}

/** input type for inserting data into table "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Challenge_Group_Active_State_Enum_Max_Fields = {
  __typename?: 'challenge_group_active_state_enum_max_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Challenge_Group_Active_State_Enum_Min_Fields = {
  __typename?: 'challenge_group_active_state_enum_min_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Mutation_Response = {
  __typename?: 'challenge_group_active_state_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Group_Active_State_Enum>
}

/** input type for inserting object relation for remote table "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Obj_Rel_Insert_Input = {
  data: Challenge_Group_Active_State_Enum_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Group_Active_State_Enum_On_Conflict>
}

/** on conflict condition type for table "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_On_Conflict = {
  constraint: Challenge_Group_Active_State_Enum_Constraint
  update_columns?: Array<Challenge_Group_Active_State_Enum_Update_Column>
  where?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_group_active_state_enum". */
export type Challenge_Group_Active_State_Enum_Order_By = {
  description?: Maybe<Order_By>
  state?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_group_active_state_enum */
export type Challenge_Group_Active_State_Enum_Pk_Columns_Input = {
  state: Scalars['String']
}

/** select columns of table "challenge_group_active_state_enum" */
export enum Challenge_Group_Active_State_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "challenge_group_active_state_enum" */
export type Challenge_Group_Active_State_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** update columns of table "challenge_group_active_state_enum" */
export enum Challenge_Group_Active_State_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** aggregated selection of "challenge_group" */
export type Challenge_Group_Aggregate = {
  __typename?: 'challenge_group_aggregate'
  aggregate?: Maybe<Challenge_Group_Aggregate_Fields>
  nodes: Array<Challenge_Group>
}

/** aggregate fields of "challenge_group" */
export type Challenge_Group_Aggregate_Fields = {
  __typename?: 'challenge_group_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Group_Max_Fields>
  min?: Maybe<Challenge_Group_Min_Fields>
}

/** aggregate fields of "challenge_group" */
export type Challenge_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Group_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge_group" */
export type Challenge_Group_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Group_Max_Order_By>
  min?: Maybe<Challenge_Group_Min_Order_By>
}

/** input type for inserting array relation for remote table "challenge_group" */
export type Challenge_Group_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Group_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Group_On_Conflict>
}

/** Boolean expression to filter rows from the table "challenge_group". All fields are combined with a logical 'AND'. */
export type Challenge_Group_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Group_Bool_Exp>>
  _not?: Maybe<Challenge_Group_Bool_Exp>
  _or?: Maybe<Array<Challenge_Group_Bool_Exp>>
  activeState?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
  active_state?: Maybe<Challenge_Group_Active_State_Enum_Enum_Comparison_Exp>
  challenges?: Maybe<Challenge_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  endDate?: Maybe<Timestamp_Comparison_Exp>
  followers?: Maybe<Challenge_Follow_Bool_Exp>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  scoreType?: Maybe<String_Comparison_Exp>
  startDate?: Maybe<Timestamp_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  userId?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_group" */
export enum Challenge_Group_Constraint {
  /** unique or primary key constraint */
  CompetitionGroupPkey = 'competition_group_pkey',
}

/** input type for inserting data into table "challenge_group" */
export type Challenge_Group_Insert_Input = {
  activeState?: Maybe<Challenge_Group_Active_State_Enum_Obj_Rel_Insert_Input>
  active_state?: Maybe<Challenge_Group_Active_State_Enum_Enum>
  challenges?: Maybe<Challenge_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['timestamp']>
  followers?: Maybe<Challenge_Follow_Arr_Rel_Insert_Input>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Obj_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['timestamp']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  userId?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Challenge_Group_Max_Fields = {
  __typename?: 'challenge_group_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['timestamp']>
  id?: Maybe<Scalars['uuid']>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['timestamp']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "challenge_group" */
export type Challenge_Group_Max_Order_By = {
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  endDate?: Maybe<Order_By>
  id?: Maybe<Order_By>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Order_By>
  startDate?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Group_Min_Fields = {
  __typename?: 'challenge_group_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['timestamp']>
  id?: Maybe<Scalars['uuid']>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['timestamp']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "challenge_group" */
export type Challenge_Group_Min_Order_By = {
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  endDate?: Maybe<Order_By>
  id?: Maybe<Order_By>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Order_By>
  startDate?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userId?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge_group" */
export type Challenge_Group_Mutation_Response = {
  __typename?: 'challenge_group_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Group>
}

/** input type for inserting object relation for remote table "challenge_group" */
export type Challenge_Group_Obj_Rel_Insert_Input = {
  data: Challenge_Group_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Group_On_Conflict>
}

/** on conflict condition type for table "challenge_group" */
export type Challenge_Group_On_Conflict = {
  constraint: Challenge_Group_Constraint
  update_columns?: Array<Challenge_Group_Update_Column>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_group". */
export type Challenge_Group_Order_By = {
  activeState?: Maybe<Challenge_Group_Active_State_Enum_Order_By>
  active_state?: Maybe<Order_By>
  challenges_aggregate?: Maybe<Challenge_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  endDate?: Maybe<Order_By>
  followers_aggregate?: Maybe<Challenge_Follow_Aggregate_Order_By>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Order_By>
  id?: Maybe<Order_By>
  scoreType?: Maybe<Order_By>
  startDate?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  userId?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_group */
export type Challenge_Group_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "challenge_group" */
export enum Challenge_Group_Select_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreType = 'scoreType',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/**
 * This table works on two values. The first is the id, mutliple series with the same ID are connected. The next is the series. These numbers are assigned to each item in the series, are not consecutive, but do preserve order.
 *
 *
 * columns and relationships of "challenge_group_series_frequency"
 */
export type Challenge_Group_Series_Frequency = {
  __typename?: 'challenge_group_series_frequency'
  /** An object relationship */
  challengeGroup: Challenge_Group
  challenge_group_id: Scalars['uuid']
  frequency: Challenge_Group_Series_Frequency_Enum_Enum
  id: Scalars['Int']
  /** An array relationship */
  otherValues: Array<Challenge_Group_Series_Frequency>
  /** An aggregate relationship */
  otherValues_aggregate: Challenge_Group_Series_Frequency_Aggregate
  series: Scalars['Int']
}

/**
 * This table works on two values. The first is the id, mutliple series with the same ID are connected. The next is the series. These numbers are assigned to each item in the series, are not consecutive, but do preserve order.
 *
 *
 * columns and relationships of "challenge_group_series_frequency"
 */
export type Challenge_Group_Series_FrequencyOtherValuesArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

/**
 * This table works on two values. The first is the id, mutliple series with the same ID are connected. The next is the series. These numbers are assigned to each item in the series, are not consecutive, but do preserve order.
 *
 *
 * columns and relationships of "challenge_group_series_frequency"
 */
export type Challenge_Group_Series_FrequencyOtherValues_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

/** aggregated selection of "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Aggregate = {
  __typename?: 'challenge_group_series_frequency_aggregate'
  aggregate?: Maybe<Challenge_Group_Series_Frequency_Aggregate_Fields>
  nodes: Array<Challenge_Group_Series_Frequency>
}

/** aggregate fields of "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Aggregate_Fields = {
  __typename?: 'challenge_group_series_frequency_aggregate_fields'
  avg?: Maybe<Challenge_Group_Series_Frequency_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Challenge_Group_Series_Frequency_Max_Fields>
  min?: Maybe<Challenge_Group_Series_Frequency_Min_Fields>
  stddev?: Maybe<Challenge_Group_Series_Frequency_Stddev_Fields>
  stddev_pop?: Maybe<Challenge_Group_Series_Frequency_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Challenge_Group_Series_Frequency_Stddev_Samp_Fields>
  sum?: Maybe<Challenge_Group_Series_Frequency_Sum_Fields>
  var_pop?: Maybe<Challenge_Group_Series_Frequency_Var_Pop_Fields>
  var_samp?: Maybe<Challenge_Group_Series_Frequency_Var_Samp_Fields>
  variance?: Maybe<Challenge_Group_Series_Frequency_Variance_Fields>
}

/** aggregate fields of "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Group_Series_Frequency_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Challenge_Group_Series_Frequency_Max_Order_By>
  min?: Maybe<Challenge_Group_Series_Frequency_Min_Order_By>
  stddev?: Maybe<Challenge_Group_Series_Frequency_Stddev_Order_By>
  stddev_pop?: Maybe<Challenge_Group_Series_Frequency_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Challenge_Group_Series_Frequency_Stddev_Samp_Order_By>
  sum?: Maybe<Challenge_Group_Series_Frequency_Sum_Order_By>
  var_pop?: Maybe<Challenge_Group_Series_Frequency_Var_Pop_Order_By>
  var_samp?: Maybe<Challenge_Group_Series_Frequency_Var_Samp_Order_By>
  variance?: Maybe<Challenge_Group_Series_Frequency_Variance_Order_By>
}

/** input type for inserting array relation for remote table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Group_Series_Frequency_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_On_Conflict>
}

/** aggregate avg on columns */
export type Challenge_Group_Series_Frequency_Avg_Fields = {
  __typename?: 'challenge_group_series_frequency_avg_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Avg_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "challenge_group_series_frequency". All fields are combined with a logical 'AND'. */
export type Challenge_Group_Series_Frequency_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Group_Series_Frequency_Bool_Exp>>
  _not?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
  _or?: Maybe<Array<Challenge_Group_Series_Frequency_Bool_Exp>>
  challengeGroup?: Maybe<Challenge_Group_Bool_Exp>
  challenge_group_id?: Maybe<Uuid_Comparison_Exp>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Enum_Enum_Comparison_Exp>
  id?: Maybe<Int_Comparison_Exp>
  otherValues?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
  series?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_group_series_frequency" */
export enum Challenge_Group_Series_Frequency_Constraint {
  /** unique or primary key constraint */
  ChallengeGroupSeriesFrequencyChallengeGroupIdKey = 'challenge_group_series_frequency_challenge_group_id_key',
  /** unique or primary key constraint */
  ChallengeGroupSeriesFrequencyPkey = 'challenge_group_series_frequency_pkey',
}

/** columns and relationships of "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum = {
  __typename?: 'challenge_group_series_frequency_enum'
  description: Scalars['String']
  frequency: Scalars['String']
}

/** aggregated selection of "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Aggregate = {
  __typename?: 'challenge_group_series_frequency_enum_aggregate'
  aggregate?: Maybe<Challenge_Group_Series_Frequency_Enum_Aggregate_Fields>
  nodes: Array<Challenge_Group_Series_Frequency_Enum>
}

/** aggregate fields of "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Aggregate_Fields = {
  __typename?: 'challenge_group_series_frequency_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Challenge_Group_Series_Frequency_Enum_Max_Fields>
  min?: Maybe<Challenge_Group_Series_Frequency_Enum_Min_Fields>
}

/** aggregate fields of "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "challenge_group_series_frequency_enum". All fields are combined with a logical 'AND'. */
export type Challenge_Group_Series_Frequency_Enum_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Bool_Exp>>
  _not?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
  _or?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  frequency?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "challenge_group_series_frequency_enum" */
export enum Challenge_Group_Series_Frequency_Enum_Constraint {
  /** unique or primary key constraint */
  ChallengeGroupSeriesFrequencyEnumPkey = 'challenge_group_series_frequency_enum_pkey',
}

export enum Challenge_Group_Series_Frequency_Enum_Enum {
  /** This happens daily. */
  Daily = 'DAILY',
  /** This happens monthly. */
  Monthly = 'MONTHLY',
  /** This happens once. */
  OneOff = 'ONE_OFF',
  /** This happens weekly. */
  Weekly = 'WEEKLY',
}

/** Boolean expression to compare columns of type "challenge_group_series_frequency_enum_enum". All fields are combined with logical 'AND'. */
export type Challenge_Group_Series_Frequency_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<Challenge_Group_Series_Frequency_Enum_Enum>
  _in?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Challenge_Group_Series_Frequency_Enum_Enum>
  _nin?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Enum>>
}

/** input type for inserting data into table "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  frequency?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Challenge_Group_Series_Frequency_Enum_Max_Fields = {
  __typename?: 'challenge_group_series_frequency_enum_max_fields'
  description?: Maybe<Scalars['String']>
  frequency?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Challenge_Group_Series_Frequency_Enum_Min_Fields = {
  __typename?: 'challenge_group_series_frequency_enum_min_fields'
  description?: Maybe<Scalars['String']>
  frequency?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Mutation_Response = {
  __typename?: 'challenge_group_series_frequency_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Group_Series_Frequency_Enum>
}

/** on conflict condition type for table "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_On_Conflict = {
  constraint: Challenge_Group_Series_Frequency_Enum_Constraint
  update_columns?: Array<Challenge_Group_Series_Frequency_Enum_Update_Column>
  where?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_group_series_frequency_enum". */
export type Challenge_Group_Series_Frequency_Enum_Order_By = {
  description?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_group_series_frequency_enum */
export type Challenge_Group_Series_Frequency_Enum_Pk_Columns_Input = {
  frequency: Scalars['String']
}

/** select columns of table "challenge_group_series_frequency_enum" */
export enum Challenge_Group_Series_Frequency_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
}

/** input type for updating data in table "challenge_group_series_frequency_enum" */
export type Challenge_Group_Series_Frequency_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  frequency?: Maybe<Scalars['String']>
}

/** update columns of table "challenge_group_series_frequency_enum" */
export enum Challenge_Group_Series_Frequency_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
}

/** input type for incrementing numeric columns in table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Inc_Input = {
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Insert_Input = {
  challengeGroup?: Maybe<Challenge_Group_Obj_Rel_Insert_Input>
  challenge_group_id?: Maybe<Scalars['uuid']>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Enum_Enum>
  id?: Maybe<Scalars['Int']>
  otherValues?: Maybe<Challenge_Group_Series_Frequency_Arr_Rel_Insert_Input>
  series?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Challenge_Group_Series_Frequency_Max_Fields = {
  __typename?: 'challenge_group_series_frequency_max_fields'
  challenge_group_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Max_Order_By = {
  challenge_group_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Group_Series_Frequency_Min_Fields = {
  __typename?: 'challenge_group_series_frequency_min_fields'
  challenge_group_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Min_Order_By = {
  challenge_group_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Mutation_Response = {
  __typename?: 'challenge_group_series_frequency_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Group_Series_Frequency>
}

/** input type for inserting object relation for remote table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Obj_Rel_Insert_Input = {
  data: Challenge_Group_Series_Frequency_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_On_Conflict>
}

/** on conflict condition type for table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_On_Conflict = {
  constraint: Challenge_Group_Series_Frequency_Constraint
  update_columns?: Array<Challenge_Group_Series_Frequency_Update_Column>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

/** Ordering options when selecting data from "challenge_group_series_frequency". */
export type Challenge_Group_Series_Frequency_Order_By = {
  challengeGroup?: Maybe<Challenge_Group_Order_By>
  challenge_group_id?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  otherValues_aggregate?: Maybe<Challenge_Group_Series_Frequency_Aggregate_Order_By>
  series?: Maybe<Order_By>
}

/** primary key columns input for table: challenge_group_series_frequency */
export type Challenge_Group_Series_Frequency_Pk_Columns_Input = {
  id: Scalars['Int']
  series: Scalars['Int']
}

/** select columns of table "challenge_group_series_frequency" */
export enum Challenge_Group_Series_Frequency_Select_Column {
  /** column name */
  ChallengeGroupId = 'challenge_group_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Series = 'series',
}

/** input type for updating data in table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Set_Input = {
  challenge_group_id?: Maybe<Scalars['uuid']>
  frequency?: Maybe<Challenge_Group_Series_Frequency_Enum_Enum>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Challenge_Group_Series_Frequency_Stddev_Fields = {
  __typename?: 'challenge_group_series_frequency_stddev_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Stddev_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Challenge_Group_Series_Frequency_Stddev_Pop_Fields = {
  __typename?: 'challenge_group_series_frequency_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Challenge_Group_Series_Frequency_Stddev_Samp_Fields = {
  __typename?: 'challenge_group_series_frequency_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate sum on columns */
export type Challenge_Group_Series_Frequency_Sum_Fields = {
  __typename?: 'challenge_group_series_frequency_sum_fields'
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Sum_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** update columns of table "challenge_group_series_frequency" */
export enum Challenge_Group_Series_Frequency_Update_Column {
  /** column name */
  ChallengeGroupId = 'challenge_group_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Series = 'series',
}

/** aggregate var_pop on columns */
export type Challenge_Group_Series_Frequency_Var_Pop_Fields = {
  __typename?: 'challenge_group_series_frequency_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Var_Pop_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Challenge_Group_Series_Frequency_Var_Samp_Fields = {
  __typename?: 'challenge_group_series_frequency_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Var_Samp_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Challenge_Group_Series_Frequency_Variance_Fields = {
  __typename?: 'challenge_group_series_frequency_variance_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "challenge_group_series_frequency" */
export type Challenge_Group_Series_Frequency_Variance_Order_By = {
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** columns and relationships of "challenge_group_series_missing" */
export type Challenge_Group_Series_Missing = {
  __typename?: 'challenge_group_series_missing'
  challenge_group_id?: Maybe<Scalars['uuid']>
  frequency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** aggregated selection of "challenge_group_series_missing" */
export type Challenge_Group_Series_Missing_Aggregate = {
  __typename?: 'challenge_group_series_missing_aggregate'
  aggregate?: Maybe<Challenge_Group_Series_Missing_Aggregate_Fields>
  nodes: Array<Challenge_Group_Series_Missing>
}

/** aggregate fields of "challenge_group_series_missing" */
export type Challenge_Group_Series_Missing_Aggregate_Fields = {
  __typename?: 'challenge_group_series_missing_aggregate_fields'
  avg?: Maybe<Challenge_Group_Series_Missing_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Challenge_Group_Series_Missing_Max_Fields>
  min?: Maybe<Challenge_Group_Series_Missing_Min_Fields>
  stddev?: Maybe<Challenge_Group_Series_Missing_Stddev_Fields>
  stddev_pop?: Maybe<Challenge_Group_Series_Missing_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Challenge_Group_Series_Missing_Stddev_Samp_Fields>
  sum?: Maybe<Challenge_Group_Series_Missing_Sum_Fields>
  var_pop?: Maybe<Challenge_Group_Series_Missing_Var_Pop_Fields>
  var_samp?: Maybe<Challenge_Group_Series_Missing_Var_Samp_Fields>
  variance?: Maybe<Challenge_Group_Series_Missing_Variance_Fields>
}

/** aggregate fields of "challenge_group_series_missing" */
export type Challenge_Group_Series_Missing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Group_Series_Missing_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Challenge_Group_Series_Missing_Avg_Fields = {
  __typename?: 'challenge_group_series_missing_avg_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "challenge_group_series_missing". All fields are combined with a logical 'AND'. */
export type Challenge_Group_Series_Missing_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Group_Series_Missing_Bool_Exp>>
  _not?: Maybe<Challenge_Group_Series_Missing_Bool_Exp>
  _or?: Maybe<Array<Challenge_Group_Series_Missing_Bool_Exp>>
  challenge_group_id?: Maybe<Uuid_Comparison_Exp>
  frequency?: Maybe<String_Comparison_Exp>
  id?: Maybe<Int_Comparison_Exp>
  series?: Maybe<Int_Comparison_Exp>
}

/** aggregate max on columns */
export type Challenge_Group_Series_Missing_Max_Fields = {
  __typename?: 'challenge_group_series_missing_max_fields'
  challenge_group_id?: Maybe<Scalars['uuid']>
  frequency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Challenge_Group_Series_Missing_Min_Fields = {
  __typename?: 'challenge_group_series_missing_min_fields'
  challenge_group_id?: Maybe<Scalars['uuid']>
  frequency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** Ordering options when selecting data from "challenge_group_series_missing". */
export type Challenge_Group_Series_Missing_Order_By = {
  challenge_group_id?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  series?: Maybe<Order_By>
}

/** select columns of table "challenge_group_series_missing" */
export enum Challenge_Group_Series_Missing_Select_Column {
  /** column name */
  ChallengeGroupId = 'challenge_group_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Series = 'series',
}

/** aggregate stddev on columns */
export type Challenge_Group_Series_Missing_Stddev_Fields = {
  __typename?: 'challenge_group_series_missing_stddev_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Challenge_Group_Series_Missing_Stddev_Pop_Fields = {
  __typename?: 'challenge_group_series_missing_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Challenge_Group_Series_Missing_Stddev_Samp_Fields = {
  __typename?: 'challenge_group_series_missing_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** aggregate sum on columns */
export type Challenge_Group_Series_Missing_Sum_Fields = {
  __typename?: 'challenge_group_series_missing_sum_fields'
  id?: Maybe<Scalars['Int']>
  series?: Maybe<Scalars['Int']>
}

/** aggregate var_pop on columns */
export type Challenge_Group_Series_Missing_Var_Pop_Fields = {
  __typename?: 'challenge_group_series_missing_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Challenge_Group_Series_Missing_Var_Samp_Fields = {
  __typename?: 'challenge_group_series_missing_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Challenge_Group_Series_Missing_Variance_Fields = {
  __typename?: 'challenge_group_series_missing_variance_fields'
  id?: Maybe<Scalars['Float']>
  series?: Maybe<Scalars['Float']>
}

/** input type for updating data in table "challenge_group" */
export type Challenge_Group_Set_Input = {
  active_state?: Maybe<Challenge_Group_Active_State_Enum_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['timestamp']>
  id?: Maybe<Scalars['uuid']>
  /** Type of score that people are allowed to enter */
  scoreType?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['timestamp']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** update columns of table "challenge_group" */
export enum Challenge_Group_Update_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreType = 'scoreType',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
}

/** input type for inserting data into table "challenge" */
export type Challenge_Insert_Input = {
  activeState?: Maybe<Challenge_Active_State_Enum_Obj_Rel_Insert_Input>
  active_state?: Maybe<Challenge_Active_State_Enum_Enum>
  challengeEntries?: Maybe<Challenge_Entry_Arr_Rel_Insert_Input>
  challengeGroup?: Maybe<Challenge_Group_Obj_Rel_Insert_Input>
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Challenge_Max_Fields = {
  __typename?: 'challenge_max_fields'
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "challenge" */
export type Challenge_Max_Order_By = {
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Challenge_Min_Fields = {
  __typename?: 'challenge_min_fields'
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "challenge" */
export type Challenge_Min_Order_By = {
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "challenge" */
export type Challenge_Mutation_Response = {
  __typename?: 'challenge_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Challenge>
}

/** input type for inserting object relation for remote table "challenge" */
export type Challenge_Obj_Rel_Insert_Input = {
  data: Challenge_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<Challenge_On_Conflict>
}

/** on conflict condition type for table "challenge" */
export type Challenge_On_Conflict = {
  constraint: Challenge_Constraint
  update_columns?: Array<Challenge_Update_Column>
  where?: Maybe<Challenge_Bool_Exp>
}

/** Ordering options when selecting data from "challenge". */
export type Challenge_Order_By = {
  activeState?: Maybe<Challenge_Active_State_Enum_Order_By>
  active_state?: Maybe<Order_By>
  challengeEntries_aggregate?: Maybe<Challenge_Entry_Aggregate_Order_By>
  challengeGroup?: Maybe<Challenge_Group_Order_By>
  challengeGroupId?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: challenge */
export type Challenge_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "challenge" */
export enum Challenge_Select_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  ChallengeGroupId = 'challengeGroupId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "challenge" */
export type Challenge_Set_Input = {
  active_state?: Maybe<Challenge_Active_State_Enum_Enum>
  challengeGroupId?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "challenge" */
export enum Challenge_Update_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  ChallengeGroupId = 'challengeGroupId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>
  _eq?: Maybe<Scalars['jsonb']>
  _gt?: Maybe<Scalars['jsonb']>
  _gte?: Maybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Scalars['jsonb']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['jsonb']>
  _lte?: Maybe<Scalars['jsonb']>
  _neq?: Maybe<Scalars['jsonb']>
  _nin?: Maybe<Array<Scalars['jsonb']>>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "boolean" */
  delete_boolean?: Maybe<Boolean_Mutation_Response>
  /** delete single row from the table: "boolean" */
  delete_boolean_by_pk?: Maybe<Boolean>
  /** delete data from the table: "challenge" */
  delete_challenge?: Maybe<Challenge_Mutation_Response>
  /** delete data from the table: "challenge_active_state_enum" */
  delete_challenge_active_state_enum?: Maybe<Challenge_Active_State_Enum_Mutation_Response>
  /** delete single row from the table: "challenge_active_state_enum" */
  delete_challenge_active_state_enum_by_pk?: Maybe<Challenge_Active_State_Enum>
  /** delete single row from the table: "challenge" */
  delete_challenge_by_pk?: Maybe<Challenge>
  /** delete data from the table: "challenge_entry" */
  delete_challenge_entry?: Maybe<Challenge_Entry_Mutation_Response>
  /** delete data from the table: "challenge_entry_active_state_enum" */
  delete_challenge_entry_active_state_enum?: Maybe<Challenge_Entry_Active_State_Enum_Mutation_Response>
  /** delete single row from the table: "challenge_entry_active_state_enum" */
  delete_challenge_entry_active_state_enum_by_pk?: Maybe<Challenge_Entry_Active_State_Enum>
  /** delete single row from the table: "challenge_entry" */
  delete_challenge_entry_by_pk?: Maybe<Challenge_Entry>
  /** delete data from the table: "challenge_entry_comment" */
  delete_challenge_entry_comment?: Maybe<Challenge_Entry_Comment_Mutation_Response>
  /** delete single row from the table: "challenge_entry_comment" */
  delete_challenge_entry_comment_by_pk?: Maybe<Challenge_Entry_Comment>
  /** delete data from the table: "challenge_follow" */
  delete_challenge_follow?: Maybe<Challenge_Follow_Mutation_Response>
  /** delete single row from the table: "challenge_follow" */
  delete_challenge_follow_by_pk?: Maybe<Challenge_Follow>
  /** delete data from the table: "challenge_group" */
  delete_challenge_group?: Maybe<Challenge_Group_Mutation_Response>
  /** delete data from the table: "challenge_group_active_state_enum" */
  delete_challenge_group_active_state_enum?: Maybe<Challenge_Group_Active_State_Enum_Mutation_Response>
  /** delete single row from the table: "challenge_group_active_state_enum" */
  delete_challenge_group_active_state_enum_by_pk?: Maybe<Challenge_Group_Active_State_Enum>
  /** delete single row from the table: "challenge_group" */
  delete_challenge_group_by_pk?: Maybe<Challenge_Group>
  /** delete data from the table: "challenge_group_series_frequency" */
  delete_challenge_group_series_frequency?: Maybe<Challenge_Group_Series_Frequency_Mutation_Response>
  /** delete single row from the table: "challenge_group_series_frequency" */
  delete_challenge_group_series_frequency_by_pk?: Maybe<Challenge_Group_Series_Frequency>
  /** delete data from the table: "challenge_group_series_frequency_enum" */
  delete_challenge_group_series_frequency_enum?: Maybe<Challenge_Group_Series_Frequency_Enum_Mutation_Response>
  /** delete single row from the table: "challenge_group_series_frequency_enum" */
  delete_challenge_group_series_frequency_enum_by_pk?: Maybe<Challenge_Group_Series_Frequency_Enum>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete data from the table: "user_active_state_enum" */
  delete_user_active_state_enum?: Maybe<User_Active_State_Enum_Mutation_Response>
  /** delete single row from the table: "user_active_state_enum" */
  delete_user_active_state_enum_by_pk?: Maybe<User_Active_State_Enum>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_identifiable_info" */
  delete_user_identifiable_info?: Maybe<User_Identifiable_Info_Mutation_Response>
  /** delete single row from the table: "user_identifiable_info" */
  delete_user_identifiable_info_by_pk?: Maybe<User_Identifiable_Info>
  /** delete data from the table: "user_identifiable_info_private" */
  delete_user_identifiable_info_private?: Maybe<User_Identifiable_Info_Private_Mutation_Response>
  /** delete data from the table: "user_private" */
  delete_user_private?: Maybe<User_Private_Mutation_Response>
  /** delete data from the table: "user_type_enum" */
  delete_user_type_enum?: Maybe<User_Type_Enum_Mutation_Response>
  /** delete single row from the table: "user_type_enum" */
  delete_user_type_enum_by_pk?: Maybe<User_Type_Enum>
  /** insert data into the table: "boolean" */
  insert_boolean?: Maybe<Boolean_Mutation_Response>
  /** insert a single row into the table: "boolean" */
  insert_boolean_one?: Maybe<Boolean>
  /** insert data into the table: "challenge" */
  insert_challenge?: Maybe<Challenge_Mutation_Response>
  /** insert data into the table: "challenge_active_state_enum" */
  insert_challenge_active_state_enum?: Maybe<Challenge_Active_State_Enum_Mutation_Response>
  /** insert a single row into the table: "challenge_active_state_enum" */
  insert_challenge_active_state_enum_one?: Maybe<Challenge_Active_State_Enum>
  /** insert data into the table: "challenge_entry" */
  insert_challenge_entry?: Maybe<Challenge_Entry_Mutation_Response>
  /** insert data into the table: "challenge_entry_active_state_enum" */
  insert_challenge_entry_active_state_enum?: Maybe<Challenge_Entry_Active_State_Enum_Mutation_Response>
  /** insert a single row into the table: "challenge_entry_active_state_enum" */
  insert_challenge_entry_active_state_enum_one?: Maybe<Challenge_Entry_Active_State_Enum>
  /** insert data into the table: "challenge_entry_comment" */
  insert_challenge_entry_comment?: Maybe<Challenge_Entry_Comment_Mutation_Response>
  /** insert a single row into the table: "challenge_entry_comment" */
  insert_challenge_entry_comment_one?: Maybe<Challenge_Entry_Comment>
  /** insert a single row into the table: "challenge_entry" */
  insert_challenge_entry_one?: Maybe<Challenge_Entry>
  /** insert data into the table: "challenge_follow" */
  insert_challenge_follow?: Maybe<Challenge_Follow_Mutation_Response>
  /** insert a single row into the table: "challenge_follow" */
  insert_challenge_follow_one?: Maybe<Challenge_Follow>
  /** insert data into the table: "challenge_group" */
  insert_challenge_group?: Maybe<Challenge_Group_Mutation_Response>
  /** insert data into the table: "challenge_group_active_state_enum" */
  insert_challenge_group_active_state_enum?: Maybe<Challenge_Group_Active_State_Enum_Mutation_Response>
  /** insert a single row into the table: "challenge_group_active_state_enum" */
  insert_challenge_group_active_state_enum_one?: Maybe<Challenge_Group_Active_State_Enum>
  /** insert a single row into the table: "challenge_group" */
  insert_challenge_group_one?: Maybe<Challenge_Group>
  /** insert data into the table: "challenge_group_series_frequency" */
  insert_challenge_group_series_frequency?: Maybe<Challenge_Group_Series_Frequency_Mutation_Response>
  /** insert data into the table: "challenge_group_series_frequency_enum" */
  insert_challenge_group_series_frequency_enum?: Maybe<Challenge_Group_Series_Frequency_Enum_Mutation_Response>
  /** insert a single row into the table: "challenge_group_series_frequency_enum" */
  insert_challenge_group_series_frequency_enum_one?: Maybe<Challenge_Group_Series_Frequency_Enum>
  /** insert a single row into the table: "challenge_group_series_frequency" */
  insert_challenge_group_series_frequency_one?: Maybe<Challenge_Group_Series_Frequency>
  /** insert a single row into the table: "challenge" */
  insert_challenge_one?: Maybe<Challenge>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_active_state_enum" */
  insert_user_active_state_enum?: Maybe<User_Active_State_Enum_Mutation_Response>
  /** insert a single row into the table: "user_active_state_enum" */
  insert_user_active_state_enum_one?: Maybe<User_Active_State_Enum>
  /** insert data into the table: "user_identifiable_info" */
  insert_user_identifiable_info?: Maybe<User_Identifiable_Info_Mutation_Response>
  /** insert a single row into the table: "user_identifiable_info" */
  insert_user_identifiable_info_one?: Maybe<User_Identifiable_Info>
  /** insert data into the table: "user_identifiable_info_private" */
  insert_user_identifiable_info_private?: Maybe<User_Identifiable_Info_Private_Mutation_Response>
  /** insert a single row into the table: "user_identifiable_info_private" */
  insert_user_identifiable_info_private_one?: Maybe<User_Identifiable_Info_Private>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_private" */
  insert_user_private?: Maybe<User_Private_Mutation_Response>
  /** insert a single row into the table: "user_private" */
  insert_user_private_one?: Maybe<User_Private>
  /** insert data into the table: "user_type_enum" */
  insert_user_type_enum?: Maybe<User_Type_Enum_Mutation_Response>
  /** insert a single row into the table: "user_type_enum" */
  insert_user_type_enum_one?: Maybe<User_Type_Enum>
  /** update data of the table: "boolean" */
  update_boolean?: Maybe<Boolean_Mutation_Response>
  /** update single row of the table: "boolean" */
  update_boolean_by_pk?: Maybe<Boolean>
  /** update data of the table: "challenge" */
  update_challenge?: Maybe<Challenge_Mutation_Response>
  /** update data of the table: "challenge_active_state_enum" */
  update_challenge_active_state_enum?: Maybe<Challenge_Active_State_Enum_Mutation_Response>
  /** update single row of the table: "challenge_active_state_enum" */
  update_challenge_active_state_enum_by_pk?: Maybe<Challenge_Active_State_Enum>
  /** update single row of the table: "challenge" */
  update_challenge_by_pk?: Maybe<Challenge>
  /** update data of the table: "challenge_entry" */
  update_challenge_entry?: Maybe<Challenge_Entry_Mutation_Response>
  /** update data of the table: "challenge_entry_active_state_enum" */
  update_challenge_entry_active_state_enum?: Maybe<Challenge_Entry_Active_State_Enum_Mutation_Response>
  /** update single row of the table: "challenge_entry_active_state_enum" */
  update_challenge_entry_active_state_enum_by_pk?: Maybe<Challenge_Entry_Active_State_Enum>
  /** update single row of the table: "challenge_entry" */
  update_challenge_entry_by_pk?: Maybe<Challenge_Entry>
  /** update data of the table: "challenge_entry_comment" */
  update_challenge_entry_comment?: Maybe<Challenge_Entry_Comment_Mutation_Response>
  /** update single row of the table: "challenge_entry_comment" */
  update_challenge_entry_comment_by_pk?: Maybe<Challenge_Entry_Comment>
  /** update data of the table: "challenge_follow" */
  update_challenge_follow?: Maybe<Challenge_Follow_Mutation_Response>
  /** update single row of the table: "challenge_follow" */
  update_challenge_follow_by_pk?: Maybe<Challenge_Follow>
  /** update data of the table: "challenge_group" */
  update_challenge_group?: Maybe<Challenge_Group_Mutation_Response>
  /** update data of the table: "challenge_group_active_state_enum" */
  update_challenge_group_active_state_enum?: Maybe<Challenge_Group_Active_State_Enum_Mutation_Response>
  /** update single row of the table: "challenge_group_active_state_enum" */
  update_challenge_group_active_state_enum_by_pk?: Maybe<Challenge_Group_Active_State_Enum>
  /** update single row of the table: "challenge_group" */
  update_challenge_group_by_pk?: Maybe<Challenge_Group>
  /** update data of the table: "challenge_group_series_frequency" */
  update_challenge_group_series_frequency?: Maybe<Challenge_Group_Series_Frequency_Mutation_Response>
  /** update single row of the table: "challenge_group_series_frequency" */
  update_challenge_group_series_frequency_by_pk?: Maybe<Challenge_Group_Series_Frequency>
  /** update data of the table: "challenge_group_series_frequency_enum" */
  update_challenge_group_series_frequency_enum?: Maybe<Challenge_Group_Series_Frequency_Enum_Mutation_Response>
  /** update single row of the table: "challenge_group_series_frequency_enum" */
  update_challenge_group_series_frequency_enum_by_pk?: Maybe<Challenge_Group_Series_Frequency_Enum>
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update data of the table: "user_active_state_enum" */
  update_user_active_state_enum?: Maybe<User_Active_State_Enum_Mutation_Response>
  /** update single row of the table: "user_active_state_enum" */
  update_user_active_state_enum_by_pk?: Maybe<User_Active_State_Enum>
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_identifiable_info" */
  update_user_identifiable_info?: Maybe<User_Identifiable_Info_Mutation_Response>
  /** update single row of the table: "user_identifiable_info" */
  update_user_identifiable_info_by_pk?: Maybe<User_Identifiable_Info>
  /** update data of the table: "user_identifiable_info_private" */
  update_user_identifiable_info_private?: Maybe<User_Identifiable_Info_Private_Mutation_Response>
  /** update data of the table: "user_private" */
  update_user_private?: Maybe<User_Private_Mutation_Response>
  /** update data of the table: "user_type_enum" */
  update_user_type_enum?: Maybe<User_Type_Enum_Mutation_Response>
  /** update single row of the table: "user_type_enum" */
  update_user_type_enum_by_pk?: Maybe<User_Type_Enum>
}

/** mutation root */
export type Mutation_RootDelete_BooleanArgs = {
  where: Boolean_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Boolean_By_PkArgs = {
  boolean: Scalars['Boolean']
}

/** mutation root */
export type Mutation_RootDelete_ChallengeArgs = {
  where: Challenge_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Active_State_EnumArgs = {
  where: Challenge_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_EntryArgs = {
  where: Challenge_Entry_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Entry_Active_State_EnumArgs = {
  where: Challenge_Entry_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Entry_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Entry_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Entry_CommentArgs = {
  where: Challenge_Entry_Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Entry_Comment_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_FollowArgs = {
  where: Challenge_Follow_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Follow_By_PkArgs = {
  challengeGroupId: Scalars['uuid']
  userId: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_GroupArgs = {
  where: Challenge_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Active_State_EnumArgs = {
  where: Challenge_Group_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Series_FrequencyArgs = {
  where: Challenge_Group_Series_Frequency_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Series_Frequency_By_PkArgs = {
  id: Scalars['Int']
  series: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Series_Frequency_EnumArgs = {
  where: Challenge_Group_Series_Frequency_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Challenge_Group_Series_Frequency_Enum_By_PkArgs = {
  frequency: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Active_State_EnumArgs = {
  where: User_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Identifiable_InfoArgs = {
  where: User_Identifiable_Info_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Identifiable_Info_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Identifiable_Info_PrivateArgs = {
  where: User_Identifiable_Info_Private_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_PrivateArgs = {
  where: User_Private_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Type_EnumArgs = {
  where: User_Type_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Type_Enum_By_PkArgs = {
  type: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_BooleanArgs = {
  objects: Array<Boolean_Insert_Input>
  on_conflict?: Maybe<Boolean_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Boolean_OneArgs = {
  object: Boolean_Insert_Input
  on_conflict?: Maybe<Boolean_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ChallengeArgs = {
  objects: Array<Challenge_Insert_Input>
  on_conflict?: Maybe<Challenge_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Active_State_EnumArgs = {
  objects: Array<Challenge_Active_State_Enum_Insert_Input>
  on_conflict?: Maybe<Challenge_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Active_State_Enum_OneArgs = {
  object: Challenge_Active_State_Enum_Insert_Input
  on_conflict?: Maybe<Challenge_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_EntryArgs = {
  objects: Array<Challenge_Entry_Insert_Input>
  on_conflict?: Maybe<Challenge_Entry_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Entry_Active_State_EnumArgs = {
  objects: Array<Challenge_Entry_Active_State_Enum_Insert_Input>
  on_conflict?: Maybe<Challenge_Entry_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Entry_Active_State_Enum_OneArgs = {
  object: Challenge_Entry_Active_State_Enum_Insert_Input
  on_conflict?: Maybe<Challenge_Entry_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Entry_CommentArgs = {
  objects: Array<Challenge_Entry_Comment_Insert_Input>
  on_conflict?: Maybe<Challenge_Entry_Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Entry_Comment_OneArgs = {
  object: Challenge_Entry_Comment_Insert_Input
  on_conflict?: Maybe<Challenge_Entry_Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Entry_OneArgs = {
  object: Challenge_Entry_Insert_Input
  on_conflict?: Maybe<Challenge_Entry_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_FollowArgs = {
  objects: Array<Challenge_Follow_Insert_Input>
  on_conflict?: Maybe<Challenge_Follow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Follow_OneArgs = {
  object: Challenge_Follow_Insert_Input
  on_conflict?: Maybe<Challenge_Follow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_GroupArgs = {
  objects: Array<Challenge_Group_Insert_Input>
  on_conflict?: Maybe<Challenge_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Active_State_EnumArgs = {
  objects: Array<Challenge_Group_Active_State_Enum_Insert_Input>
  on_conflict?: Maybe<Challenge_Group_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Active_State_Enum_OneArgs = {
  object: Challenge_Group_Active_State_Enum_Insert_Input
  on_conflict?: Maybe<Challenge_Group_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_OneArgs = {
  object: Challenge_Group_Insert_Input
  on_conflict?: Maybe<Challenge_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Series_FrequencyArgs = {
  objects: Array<Challenge_Group_Series_Frequency_Insert_Input>
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Series_Frequency_EnumArgs = {
  objects: Array<Challenge_Group_Series_Frequency_Enum_Insert_Input>
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Series_Frequency_Enum_OneArgs = {
  object: Challenge_Group_Series_Frequency_Enum_Insert_Input
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_Group_Series_Frequency_OneArgs = {
  object: Challenge_Group_Series_Frequency_Insert_Input
  on_conflict?: Maybe<Challenge_Group_Series_Frequency_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Challenge_OneArgs = {
  object: Challenge_Insert_Input
  on_conflict?: Maybe<Challenge_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Active_State_EnumArgs = {
  objects: Array<User_Active_State_Enum_Insert_Input>
  on_conflict?: Maybe<User_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Active_State_Enum_OneArgs = {
  object: User_Active_State_Enum_Insert_Input
  on_conflict?: Maybe<User_Active_State_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Identifiable_InfoArgs = {
  objects: Array<User_Identifiable_Info_Insert_Input>
  on_conflict?: Maybe<User_Identifiable_Info_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Identifiable_Info_OneArgs = {
  object: User_Identifiable_Info_Insert_Input
  on_conflict?: Maybe<User_Identifiable_Info_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Identifiable_Info_PrivateArgs = {
  objects: Array<User_Identifiable_Info_Private_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_User_Identifiable_Info_Private_OneArgs = {
  object: User_Identifiable_Info_Private_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_PrivateArgs = {
  objects: Array<User_Private_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_User_Private_OneArgs = {
  object: User_Private_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_User_Type_EnumArgs = {
  objects: Array<User_Type_Enum_Insert_Input>
  on_conflict?: Maybe<User_Type_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Type_Enum_OneArgs = {
  object: User_Type_Enum_Insert_Input
  on_conflict?: Maybe<User_Type_Enum_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_BooleanArgs = {
  _set?: Maybe<Boolean_Set_Input>
  where: Boolean_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Boolean_By_PkArgs = {
  _set?: Maybe<Boolean_Set_Input>
  pk_columns: Boolean_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_ChallengeArgs = {
  _set?: Maybe<Challenge_Set_Input>
  where: Challenge_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Active_State_EnumArgs = {
  _set?: Maybe<Challenge_Active_State_Enum_Set_Input>
  where: Challenge_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Active_State_Enum_By_PkArgs = {
  _set?: Maybe<Challenge_Active_State_Enum_Set_Input>
  pk_columns: Challenge_Active_State_Enum_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_By_PkArgs = {
  _set?: Maybe<Challenge_Set_Input>
  pk_columns: Challenge_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_EntryArgs = {
  _append?: Maybe<Challenge_Entry_Append_Input>
  _delete_at_path?: Maybe<Challenge_Entry_Delete_At_Path_Input>
  _delete_elem?: Maybe<Challenge_Entry_Delete_Elem_Input>
  _delete_key?: Maybe<Challenge_Entry_Delete_Key_Input>
  _inc?: Maybe<Challenge_Entry_Inc_Input>
  _prepend?: Maybe<Challenge_Entry_Prepend_Input>
  _set?: Maybe<Challenge_Entry_Set_Input>
  where: Challenge_Entry_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Entry_Active_State_EnumArgs = {
  _set?: Maybe<Challenge_Entry_Active_State_Enum_Set_Input>
  where: Challenge_Entry_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Entry_Active_State_Enum_By_PkArgs = {
  _set?: Maybe<Challenge_Entry_Active_State_Enum_Set_Input>
  pk_columns: Challenge_Entry_Active_State_Enum_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Entry_By_PkArgs = {
  _append?: Maybe<Challenge_Entry_Append_Input>
  _delete_at_path?: Maybe<Challenge_Entry_Delete_At_Path_Input>
  _delete_elem?: Maybe<Challenge_Entry_Delete_Elem_Input>
  _delete_key?: Maybe<Challenge_Entry_Delete_Key_Input>
  _inc?: Maybe<Challenge_Entry_Inc_Input>
  _prepend?: Maybe<Challenge_Entry_Prepend_Input>
  _set?: Maybe<Challenge_Entry_Set_Input>
  pk_columns: Challenge_Entry_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Entry_CommentArgs = {
  _set?: Maybe<Challenge_Entry_Comment_Set_Input>
  where: Challenge_Entry_Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Entry_Comment_By_PkArgs = {
  _set?: Maybe<Challenge_Entry_Comment_Set_Input>
  pk_columns: Challenge_Entry_Comment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_FollowArgs = {
  _set?: Maybe<Challenge_Follow_Set_Input>
  where: Challenge_Follow_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Follow_By_PkArgs = {
  _set?: Maybe<Challenge_Follow_Set_Input>
  pk_columns: Challenge_Follow_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_GroupArgs = {
  _set?: Maybe<Challenge_Group_Set_Input>
  where: Challenge_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Active_State_EnumArgs = {
  _set?: Maybe<Challenge_Group_Active_State_Enum_Set_Input>
  where: Challenge_Group_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Active_State_Enum_By_PkArgs = {
  _set?: Maybe<Challenge_Group_Active_State_Enum_Set_Input>
  pk_columns: Challenge_Group_Active_State_Enum_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_By_PkArgs = {
  _set?: Maybe<Challenge_Group_Set_Input>
  pk_columns: Challenge_Group_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Series_FrequencyArgs = {
  _inc?: Maybe<Challenge_Group_Series_Frequency_Inc_Input>
  _set?: Maybe<Challenge_Group_Series_Frequency_Set_Input>
  where: Challenge_Group_Series_Frequency_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Series_Frequency_By_PkArgs = {
  _inc?: Maybe<Challenge_Group_Series_Frequency_Inc_Input>
  _set?: Maybe<Challenge_Group_Series_Frequency_Set_Input>
  pk_columns: Challenge_Group_Series_Frequency_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Series_Frequency_EnumArgs = {
  _set?: Maybe<Challenge_Group_Series_Frequency_Enum_Set_Input>
  where: Challenge_Group_Series_Frequency_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Challenge_Group_Series_Frequency_Enum_By_PkArgs = {
  _set?: Maybe<Challenge_Group_Series_Frequency_Enum_Set_Input>
  pk_columns: Challenge_Group_Series_Frequency_Enum_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Active_State_EnumArgs = {
  _set?: Maybe<User_Active_State_Enum_Set_Input>
  where: User_Active_State_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Active_State_Enum_By_PkArgs = {
  _set?: Maybe<User_Active_State_Enum_Set_Input>
  pk_columns: User_Active_State_Enum_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Identifiable_InfoArgs = {
  _set?: Maybe<User_Identifiable_Info_Set_Input>
  where: User_Identifiable_Info_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Identifiable_Info_By_PkArgs = {
  _set?: Maybe<User_Identifiable_Info_Set_Input>
  pk_columns: User_Identifiable_Info_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Identifiable_Info_PrivateArgs = {
  _set?: Maybe<User_Identifiable_Info_Private_Set_Input>
  where: User_Identifiable_Info_Private_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_PrivateArgs = {
  _set?: Maybe<User_Private_Set_Input>
  where: User_Private_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Type_EnumArgs = {
  _set?: Maybe<User_Type_Enum_Set_Input>
  where: User_Type_Enum_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Type_Enum_By_PkArgs = {
  _set?: Maybe<User_Type_Enum_Set_Input>
  pk_columns: User_Type_Enum_Pk_Columns_Input
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "boolean" */
  boolean: Array<Boolean>
  /** fetch aggregated fields from the table: "boolean" */
  boolean_aggregate: Boolean_Aggregate
  /** fetch data from the table: "boolean" using primary key columns */
  boolean_by_pk?: Maybe<Boolean>
  /** fetch data from the table: "challenge" */
  challenge: Array<Challenge>
  /** fetch data from the table: "challenge_active_state_enum" */
  challenge_active_state_enum: Array<Challenge_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_active_state_enum" */
  challenge_active_state_enum_aggregate: Challenge_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_active_state_enum" using primary key columns */
  challenge_active_state_enum_by_pk?: Maybe<Challenge_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge" */
  challenge_aggregate: Challenge_Aggregate
  /** fetch data from the table: "challenge" using primary key columns */
  challenge_by_pk?: Maybe<Challenge>
  /** fetch data from the table: "challenge_entry" */
  challenge_entry: Array<Challenge_Entry>
  /** fetch data from the table: "challenge_entry_active_state_enum" */
  challenge_entry_active_state_enum: Array<Challenge_Entry_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_entry_active_state_enum" */
  challenge_entry_active_state_enum_aggregate: Challenge_Entry_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_entry_active_state_enum" using primary key columns */
  challenge_entry_active_state_enum_by_pk?: Maybe<Challenge_Entry_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_entry" */
  challenge_entry_aggregate: Challenge_Entry_Aggregate
  /** fetch data from the table: "challenge_entry" using primary key columns */
  challenge_entry_by_pk?: Maybe<Challenge_Entry>
  /** fetch data from the table: "challenge_entry_comment" */
  challenge_entry_comment: Array<Challenge_Entry_Comment>
  /** fetch aggregated fields from the table: "challenge_entry_comment" */
  challenge_entry_comment_aggregate: Challenge_Entry_Comment_Aggregate
  /** fetch data from the table: "challenge_entry_comment" using primary key columns */
  challenge_entry_comment_by_pk?: Maybe<Challenge_Entry_Comment>
  /** fetch data from the table: "challenge_follow" */
  challenge_follow: Array<Challenge_Follow>
  /** fetch aggregated fields from the table: "challenge_follow" */
  challenge_follow_aggregate: Challenge_Follow_Aggregate
  /** fetch data from the table: "challenge_follow" using primary key columns */
  challenge_follow_by_pk?: Maybe<Challenge_Follow>
  /** fetch data from the table: "challenge_group" */
  challenge_group: Array<Challenge_Group>
  /** fetch data from the table: "challenge_group_active_state_enum" */
  challenge_group_active_state_enum: Array<Challenge_Group_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_group_active_state_enum" */
  challenge_group_active_state_enum_aggregate: Challenge_Group_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_group_active_state_enum" using primary key columns */
  challenge_group_active_state_enum_by_pk?: Maybe<Challenge_Group_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_group" */
  challenge_group_aggregate: Challenge_Group_Aggregate
  /** fetch data from the table: "challenge_group" using primary key columns */
  challenge_group_by_pk?: Maybe<Challenge_Group>
  /** fetch data from the table: "challenge_group_series_frequency" */
  challenge_group_series_frequency: Array<Challenge_Group_Series_Frequency>
  /** fetch aggregated fields from the table: "challenge_group_series_frequency" */
  challenge_group_series_frequency_aggregate: Challenge_Group_Series_Frequency_Aggregate
  /** fetch data from the table: "challenge_group_series_frequency" using primary key columns */
  challenge_group_series_frequency_by_pk?: Maybe<Challenge_Group_Series_Frequency>
  /** fetch data from the table: "challenge_group_series_frequency_enum" */
  challenge_group_series_frequency_enum: Array<Challenge_Group_Series_Frequency_Enum>
  /** fetch aggregated fields from the table: "challenge_group_series_frequency_enum" */
  challenge_group_series_frequency_enum_aggregate: Challenge_Group_Series_Frequency_Enum_Aggregate
  /** fetch data from the table: "challenge_group_series_frequency_enum" using primary key columns */
  challenge_group_series_frequency_enum_by_pk?: Maybe<Challenge_Group_Series_Frequency_Enum>
  /** fetch data from the table: "challenge_group_series_missing" */
  challenge_group_series_missing: Array<Challenge_Group_Series_Missing>
  /** fetch aggregated fields from the table: "challenge_group_series_missing" */
  challenge_group_series_missing_aggregate: Challenge_Group_Series_Missing_Aggregate
  /** execute function "series_missing" which returns "challenge_group_series_frequency" */
  series_missing: Array<Challenge_Group_Series_Frequency>
  /** execute function "series_missing" and query aggregates on result of table type "challenge_group_series_frequency" */
  series_missing_aggregate: Challenge_Group_Series_Frequency_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_active_state_enum" */
  user_active_state_enum: Array<User_Active_State_Enum>
  /** fetch aggregated fields from the table: "user_active_state_enum" */
  user_active_state_enum_aggregate: User_Active_State_Enum_Aggregate
  /** fetch data from the table: "user_active_state_enum" using primary key columns */
  user_active_state_enum_by_pk?: Maybe<User_Active_State_Enum>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_identifiable_info" */
  user_identifiable_info: Array<User_Identifiable_Info>
  /** fetch aggregated fields from the table: "user_identifiable_info" */
  user_identifiable_info_aggregate: User_Identifiable_Info_Aggregate
  /** fetch data from the table: "user_identifiable_info" using primary key columns */
  user_identifiable_info_by_pk?: Maybe<User_Identifiable_Info>
  /** fetch data from the table: "user_identifiable_info_private" */
  user_identifiable_info_private: Array<User_Identifiable_Info_Private>
  /** fetch aggregated fields from the table: "user_identifiable_info_private" */
  user_identifiable_info_private_aggregate: User_Identifiable_Info_Private_Aggregate
  /** fetch data from the table: "user_private" */
  user_private: Array<User_Private>
  /** fetch aggregated fields from the table: "user_private" */
  user_private_aggregate: User_Private_Aggregate
  /** fetch data from the table: "user_type_enum" */
  user_type_enum: Array<User_Type_Enum>
  /** fetch aggregated fields from the table: "user_type_enum" */
  user_type_enum_aggregate: User_Type_Enum_Aggregate
  /** fetch data from the table: "user_type_enum" using primary key columns */
  user_type_enum_by_pk?: Maybe<User_Type_Enum>
  /** execute function "username_available" which returns "boolean" */
  username_available: Array<Boolean>
  /** execute function "username_available" and query aggregates on result of table type "boolean" */
  username_available_aggregate: Boolean_Aggregate
}

export type Query_RootBooleanArgs = {
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Query_RootBoolean_AggregateArgs = {
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Query_RootBoolean_By_PkArgs = {
  boolean: Scalars['Boolean']
}

export type Query_RootChallengeArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

export type Query_RootChallenge_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Query_RootChallenge_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

export type Query_RootChallenge_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootChallenge_EntryArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

export type Query_RootChallenge_Entry_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Entry_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Entry_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Query_RootChallenge_Entry_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

export type Query_RootChallenge_Entry_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootChallenge_Entry_CommentArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

export type Query_RootChallenge_Entry_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

export type Query_RootChallenge_Entry_Comment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootChallenge_FollowArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

export type Query_RootChallenge_Follow_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

export type Query_RootChallenge_Follow_By_PkArgs = {
  challengeGroupId: Scalars['uuid']
  userId: Scalars['uuid']
}

export type Query_RootChallenge_GroupArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

export type Query_RootChallenge_Group_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Group_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
}

export type Query_RootChallenge_Group_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Query_RootChallenge_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

export type Query_RootChallenge_Group_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootChallenge_Group_Series_FrequencyArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Query_RootChallenge_Group_Series_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Query_RootChallenge_Group_Series_Frequency_By_PkArgs = {
  id: Scalars['Int']
  series: Scalars['Int']
}

export type Query_RootChallenge_Group_Series_Frequency_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
}

export type Query_RootChallenge_Group_Series_Frequency_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
}

export type Query_RootChallenge_Group_Series_Frequency_Enum_By_PkArgs = {
  frequency: Scalars['String']
}

export type Query_RootChallenge_Group_Series_MissingArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Missing_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Missing_Order_By>>
  where?: Maybe<Challenge_Group_Series_Missing_Bool_Exp>
}

export type Query_RootChallenge_Group_Series_Missing_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Missing_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Missing_Order_By>>
  where?: Maybe<Challenge_Group_Series_Missing_Bool_Exp>
}

export type Query_RootSeries_MissingArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Query_RootSeries_Missing_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<User_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Active_State_Enum_Order_By>>
  where?: Maybe<User_Active_State_Enum_Bool_Exp>
}

export type Query_RootUser_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Active_State_Enum_Order_By>>
  where?: Maybe<User_Active_State_Enum_Bool_Exp>
}

export type Query_RootUser_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Identifiable_InfoArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Order_By>>
  where?: Maybe<User_Identifiable_Info_Bool_Exp>
}

export type Query_RootUser_Identifiable_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Order_By>>
  where?: Maybe<User_Identifiable_Info_Bool_Exp>
}

export type Query_RootUser_Identifiable_Info_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Identifiable_Info_PrivateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Private_Order_By>>
  where?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
}

export type Query_RootUser_Identifiable_Info_Private_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Private_Order_By>>
  where?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
}

export type Query_RootUser_PrivateArgs = {
  distinct_on?: Maybe<Array<User_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Private_Order_By>>
  where?: Maybe<User_Private_Bool_Exp>
}

export type Query_RootUser_Private_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Private_Order_By>>
  where?: Maybe<User_Private_Bool_Exp>
}

export type Query_RootUser_Type_EnumArgs = {
  distinct_on?: Maybe<Array<User_Type_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Type_Enum_Order_By>>
  where?: Maybe<User_Type_Enum_Bool_Exp>
}

export type Query_RootUser_Type_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Type_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Type_Enum_Order_By>>
  where?: Maybe<User_Type_Enum_Bool_Exp>
}

export type Query_RootUser_Type_Enum_By_PkArgs = {
  type: Scalars['String']
}

export type Query_RootUsername_AvailableArgs = {
  args: Username_Available_Args
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Query_RootUsername_Available_AggregateArgs = {
  args: Username_Available_Args
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "boolean" */
  boolean: Array<Boolean>
  /** fetch aggregated fields from the table: "boolean" */
  boolean_aggregate: Boolean_Aggregate
  /** fetch data from the table: "boolean" using primary key columns */
  boolean_by_pk?: Maybe<Boolean>
  /** fetch data from the table: "challenge" */
  challenge: Array<Challenge>
  /** fetch data from the table: "challenge_active_state_enum" */
  challenge_active_state_enum: Array<Challenge_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_active_state_enum" */
  challenge_active_state_enum_aggregate: Challenge_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_active_state_enum" using primary key columns */
  challenge_active_state_enum_by_pk?: Maybe<Challenge_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge" */
  challenge_aggregate: Challenge_Aggregate
  /** fetch data from the table: "challenge" using primary key columns */
  challenge_by_pk?: Maybe<Challenge>
  /** fetch data from the table: "challenge_entry" */
  challenge_entry: Array<Challenge_Entry>
  /** fetch data from the table: "challenge_entry_active_state_enum" */
  challenge_entry_active_state_enum: Array<Challenge_Entry_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_entry_active_state_enum" */
  challenge_entry_active_state_enum_aggregate: Challenge_Entry_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_entry_active_state_enum" using primary key columns */
  challenge_entry_active_state_enum_by_pk?: Maybe<Challenge_Entry_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_entry" */
  challenge_entry_aggregate: Challenge_Entry_Aggregate
  /** fetch data from the table: "challenge_entry" using primary key columns */
  challenge_entry_by_pk?: Maybe<Challenge_Entry>
  /** fetch data from the table: "challenge_entry_comment" */
  challenge_entry_comment: Array<Challenge_Entry_Comment>
  /** fetch aggregated fields from the table: "challenge_entry_comment" */
  challenge_entry_comment_aggregate: Challenge_Entry_Comment_Aggregate
  /** fetch data from the table: "challenge_entry_comment" using primary key columns */
  challenge_entry_comment_by_pk?: Maybe<Challenge_Entry_Comment>
  /** fetch data from the table: "challenge_follow" */
  challenge_follow: Array<Challenge_Follow>
  /** fetch aggregated fields from the table: "challenge_follow" */
  challenge_follow_aggregate: Challenge_Follow_Aggregate
  /** fetch data from the table: "challenge_follow" using primary key columns */
  challenge_follow_by_pk?: Maybe<Challenge_Follow>
  /** fetch data from the table: "challenge_group" */
  challenge_group: Array<Challenge_Group>
  /** fetch data from the table: "challenge_group_active_state_enum" */
  challenge_group_active_state_enum: Array<Challenge_Group_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_group_active_state_enum" */
  challenge_group_active_state_enum_aggregate: Challenge_Group_Active_State_Enum_Aggregate
  /** fetch data from the table: "challenge_group_active_state_enum" using primary key columns */
  challenge_group_active_state_enum_by_pk?: Maybe<Challenge_Group_Active_State_Enum>
  /** fetch aggregated fields from the table: "challenge_group" */
  challenge_group_aggregate: Challenge_Group_Aggregate
  /** fetch data from the table: "challenge_group" using primary key columns */
  challenge_group_by_pk?: Maybe<Challenge_Group>
  /** fetch data from the table: "challenge_group_series_frequency" */
  challenge_group_series_frequency: Array<Challenge_Group_Series_Frequency>
  /** fetch aggregated fields from the table: "challenge_group_series_frequency" */
  challenge_group_series_frequency_aggregate: Challenge_Group_Series_Frequency_Aggregate
  /** fetch data from the table: "challenge_group_series_frequency" using primary key columns */
  challenge_group_series_frequency_by_pk?: Maybe<Challenge_Group_Series_Frequency>
  /** fetch data from the table: "challenge_group_series_frequency_enum" */
  challenge_group_series_frequency_enum: Array<Challenge_Group_Series_Frequency_Enum>
  /** fetch aggregated fields from the table: "challenge_group_series_frequency_enum" */
  challenge_group_series_frequency_enum_aggregate: Challenge_Group_Series_Frequency_Enum_Aggregate
  /** fetch data from the table: "challenge_group_series_frequency_enum" using primary key columns */
  challenge_group_series_frequency_enum_by_pk?: Maybe<Challenge_Group_Series_Frequency_Enum>
  /** fetch data from the table: "challenge_group_series_missing" */
  challenge_group_series_missing: Array<Challenge_Group_Series_Missing>
  /** fetch aggregated fields from the table: "challenge_group_series_missing" */
  challenge_group_series_missing_aggregate: Challenge_Group_Series_Missing_Aggregate
  /** execute function "series_missing" which returns "challenge_group_series_frequency" */
  series_missing: Array<Challenge_Group_Series_Frequency>
  /** execute function "series_missing" and query aggregates on result of table type "challenge_group_series_frequency" */
  series_missing_aggregate: Challenge_Group_Series_Frequency_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_active_state_enum" */
  user_active_state_enum: Array<User_Active_State_Enum>
  /** fetch aggregated fields from the table: "user_active_state_enum" */
  user_active_state_enum_aggregate: User_Active_State_Enum_Aggregate
  /** fetch data from the table: "user_active_state_enum" using primary key columns */
  user_active_state_enum_by_pk?: Maybe<User_Active_State_Enum>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_identifiable_info" */
  user_identifiable_info: Array<User_Identifiable_Info>
  /** fetch aggregated fields from the table: "user_identifiable_info" */
  user_identifiable_info_aggregate: User_Identifiable_Info_Aggregate
  /** fetch data from the table: "user_identifiable_info" using primary key columns */
  user_identifiable_info_by_pk?: Maybe<User_Identifiable_Info>
  /** fetch data from the table: "user_identifiable_info_private" */
  user_identifiable_info_private: Array<User_Identifiable_Info_Private>
  /** fetch aggregated fields from the table: "user_identifiable_info_private" */
  user_identifiable_info_private_aggregate: User_Identifiable_Info_Private_Aggregate
  /** fetch data from the table: "user_private" */
  user_private: Array<User_Private>
  /** fetch aggregated fields from the table: "user_private" */
  user_private_aggregate: User_Private_Aggregate
  /** fetch data from the table: "user_type_enum" */
  user_type_enum: Array<User_Type_Enum>
  /** fetch aggregated fields from the table: "user_type_enum" */
  user_type_enum_aggregate: User_Type_Enum_Aggregate
  /** fetch data from the table: "user_type_enum" using primary key columns */
  user_type_enum_by_pk?: Maybe<User_Type_Enum>
  /** execute function "username_available" which returns "boolean" */
  username_available: Array<Boolean>
  /** execute function "username_available" and query aggregates on result of table type "boolean" */
  username_available_aggregate: Boolean_Aggregate
}

export type Subscription_RootBooleanArgs = {
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Subscription_RootBoolean_AggregateArgs = {
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Subscription_RootBoolean_By_PkArgs = {
  boolean: Scalars['Boolean']
}

export type Subscription_RootChallengeArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

export type Subscription_RootChallenge_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Subscription_RootChallenge_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Order_By>>
  where?: Maybe<Challenge_Bool_Exp>
}

export type Subscription_RootChallenge_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootChallenge_EntryArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Entry_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Subscription_RootChallenge_Entry_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootChallenge_Entry_CommentArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

export type Subscription_RootChallenge_Entry_Comment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootChallenge_FollowArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

export type Subscription_RootChallenge_Follow_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

export type Subscription_RootChallenge_Follow_By_PkArgs = {
  challengeGroupId: Scalars['uuid']
  userId: Scalars['uuid']
}

export type Subscription_RootChallenge_GroupArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Active_State_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Subscription_RootChallenge_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

export type Subscription_RootChallenge_Group_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootChallenge_Group_Series_FrequencyArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Series_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Series_Frequency_By_PkArgs = {
  id: Scalars['Int']
  series: Scalars['Int']
}

export type Subscription_RootChallenge_Group_Series_Frequency_EnumArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Series_Frequency_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Enum_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Enum_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Series_Frequency_Enum_By_PkArgs = {
  frequency: Scalars['String']
}

export type Subscription_RootChallenge_Group_Series_MissingArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Missing_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Missing_Order_By>>
  where?: Maybe<Challenge_Group_Series_Missing_Bool_Exp>
}

export type Subscription_RootChallenge_Group_Series_Missing_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Missing_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Missing_Order_By>>
  where?: Maybe<Challenge_Group_Series_Missing_Bool_Exp>
}

export type Subscription_RootSeries_MissingArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Subscription_RootSeries_Missing_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Series_Frequency_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Series_Frequency_Order_By>>
  where?: Maybe<Challenge_Group_Series_Frequency_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_Active_State_EnumArgs = {
  distinct_on?: Maybe<Array<User_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Active_State_Enum_Order_By>>
  where?: Maybe<User_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootUser_Active_State_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Active_State_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Active_State_Enum_Order_By>>
  where?: Maybe<User_Active_State_Enum_Bool_Exp>
}

export type Subscription_RootUser_Active_State_Enum_By_PkArgs = {
  state: Scalars['String']
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Identifiable_InfoArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Order_By>>
  where?: Maybe<User_Identifiable_Info_Bool_Exp>
}

export type Subscription_RootUser_Identifiable_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Order_By>>
  where?: Maybe<User_Identifiable_Info_Bool_Exp>
}

export type Subscription_RootUser_Identifiable_Info_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Identifiable_Info_PrivateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Private_Order_By>>
  where?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
}

export type Subscription_RootUser_Identifiable_Info_Private_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Identifiable_Info_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Identifiable_Info_Private_Order_By>>
  where?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
}

export type Subscription_RootUser_PrivateArgs = {
  distinct_on?: Maybe<Array<User_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Private_Order_By>>
  where?: Maybe<User_Private_Bool_Exp>
}

export type Subscription_RootUser_Private_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Private_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Private_Order_By>>
  where?: Maybe<User_Private_Bool_Exp>
}

export type Subscription_RootUser_Type_EnumArgs = {
  distinct_on?: Maybe<Array<User_Type_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Type_Enum_Order_By>>
  where?: Maybe<User_Type_Enum_Bool_Exp>
}

export type Subscription_RootUser_Type_Enum_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Type_Enum_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Type_Enum_Order_By>>
  where?: Maybe<User_Type_Enum_Bool_Exp>
}

export type Subscription_RootUser_Type_Enum_By_PkArgs = {
  type: Scalars['String']
}

export type Subscription_RootUsername_AvailableArgs = {
  args: Username_Available_Args
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

export type Subscription_RootUsername_Available_AggregateArgs = {
  args: Username_Available_Args
  distinct_on?: Maybe<Array<Boolean_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Boolean_Order_By>>
  where?: Maybe<Boolean_Bool_Exp>
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>
  _gt?: Maybe<Scalars['timestamp']>
  _gte?: Maybe<Scalars['timestamp']>
  _in?: Maybe<Array<Scalars['timestamp']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamp']>
  _lte?: Maybe<Scalars['timestamp']>
  _neq?: Maybe<Scalars['timestamp']>
  _nin?: Maybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>
  _gt?: Maybe<Scalars['timestamptz']>
  _gte?: Maybe<Scalars['timestamptz']>
  _in?: Maybe<Array<Scalars['timestamptz']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamptz']>
  _lte?: Maybe<Scalars['timestamptz']>
  _neq?: Maybe<Scalars['timestamptz']>
  _nin?: Maybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  /** An object relationship */
  activeState: User_Active_State_Enum
  active_state: User_Active_State_Enum_Enum
  /** An array relationship */
  challengeEntries: Array<Challenge_Entry>
  /** An aggregate relationship */
  challengeEntries_aggregate: Challenge_Entry_Aggregate
  /** An array relationship */
  challengeEntryComments: Array<Challenge_Entry_Comment>
  /** An aggregate relationship */
  challengeEntryComments_aggregate: Challenge_Entry_Comment_Aggregate
  /** An array relationship */
  challengeFollows: Array<Challenge_Follow>
  /** An aggregate relationship */
  challengeFollows_aggregate: Challenge_Follow_Aggregate
  /** An array relationship */
  challengesCreated: Array<Challenge_Group>
  /** An aggregate relationship */
  challengesCreated_aggregate: Challenge_Group_Aggregate
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  identity?: Maybe<User_Identifiable_Info>
  identity_id?: Maybe<Scalars['uuid']>
  oauth_id: Scalars['String']
  /** An object relationship */
  private?: Maybe<User_Private>
  type: User_Type_Enum_Enum
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  userType: User_Type_Enum
  username: Scalars['String']
}

/** columns and relationships of "user" */
export type UserChallengeEntriesArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengeEntries_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Order_By>>
  where?: Maybe<Challenge_Entry_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengeEntryCommentsArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengeEntryComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Entry_Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Entry_Comment_Order_By>>
  where?: Maybe<Challenge_Entry_Comment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengeFollowsArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengeFollows_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Follow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Follow_Order_By>>
  where?: Maybe<Challenge_Follow_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengesCreatedArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserChallengesCreated_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Group_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Challenge_Group_Order_By>>
  where?: Maybe<Challenge_Group_Bool_Exp>
}

/**
 * GraphQL enum table with active statuses of users
 *
 *
 * columns and relationships of "user_active_state_enum"
 */
export type User_Active_State_Enum = {
  __typename?: 'user_active_state_enum'
  description?: Maybe<Scalars['String']>
  state: Scalars['String']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
}

/**
 * GraphQL enum table with active statuses of users
 *
 *
 * columns and relationships of "user_active_state_enum"
 */
export type User_Active_State_EnumUsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/**
 * GraphQL enum table with active statuses of users
 *
 *
 * columns and relationships of "user_active_state_enum"
 */
export type User_Active_State_EnumUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** aggregated selection of "user_active_state_enum" */
export type User_Active_State_Enum_Aggregate = {
  __typename?: 'user_active_state_enum_aggregate'
  aggregate?: Maybe<User_Active_State_Enum_Aggregate_Fields>
  nodes: Array<User_Active_State_Enum>
}

/** aggregate fields of "user_active_state_enum" */
export type User_Active_State_Enum_Aggregate_Fields = {
  __typename?: 'user_active_state_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Active_State_Enum_Max_Fields>
  min?: Maybe<User_Active_State_Enum_Min_Fields>
}

/** aggregate fields of "user_active_state_enum" */
export type User_Active_State_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Active_State_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_active_state_enum". All fields are combined with a logical 'AND'. */
export type User_Active_State_Enum_Bool_Exp = {
  _and?: Maybe<Array<User_Active_State_Enum_Bool_Exp>>
  _not?: Maybe<User_Active_State_Enum_Bool_Exp>
  _or?: Maybe<Array<User_Active_State_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  state?: Maybe<String_Comparison_Exp>
  users?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "user_active_state_enum" */
export enum User_Active_State_Enum_Constraint {
  /** unique or primary key constraint */
  UserActiveStatusPkey = 'user_active_status_pkey',
}

export enum User_Active_State_Enum_Enum {
  /** Signed up user */
  Active = 'ACTIVE',
  /** Account has been deleted */
  Deleted = 'DELETED',
  /** User that has not been created but could one day. Example would be tracking scores for an event or saving a famous persons account name. */
  PreSignUp = 'PRE_SIGN_UP',
}

/** Boolean expression to compare columns of type "user_active_state_enum_enum". All fields are combined with logical 'AND'. */
export type User_Active_State_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Active_State_Enum_Enum>
  _in?: Maybe<Array<User_Active_State_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Active_State_Enum_Enum>
  _nin?: Maybe<Array<User_Active_State_Enum_Enum>>
}

/** input type for inserting data into table "user_active_state_enum" */
export type User_Active_State_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  users?: Maybe<User_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Active_State_Enum_Max_Fields = {
  __typename?: 'user_active_state_enum_max_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Active_State_Enum_Min_Fields = {
  __typename?: 'user_active_state_enum_min_fields'
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_active_state_enum" */
export type User_Active_State_Enum_Mutation_Response = {
  __typename?: 'user_active_state_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Active_State_Enum>
}

/** input type for inserting object relation for remote table "user_active_state_enum" */
export type User_Active_State_Enum_Obj_Rel_Insert_Input = {
  data: User_Active_State_Enum_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<User_Active_State_Enum_On_Conflict>
}

/** on conflict condition type for table "user_active_state_enum" */
export type User_Active_State_Enum_On_Conflict = {
  constraint: User_Active_State_Enum_Constraint
  update_columns?: Array<User_Active_State_Enum_Update_Column>
  where?: Maybe<User_Active_State_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "user_active_state_enum". */
export type User_Active_State_Enum_Order_By = {
  description?: Maybe<Order_By>
  state?: Maybe<Order_By>
  users_aggregate?: Maybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: user_active_state_enum */
export type User_Active_State_Enum_Pk_Columns_Input = {
  state: Scalars['String']
}

/** select columns of table "user_active_state_enum" */
export enum User_Active_State_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "user_active_state_enum" */
export type User_Active_State_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

/** update columns of table "user_active_state_enum" */
export enum User_Active_State_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  State = 'state',
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Max_Order_By>
  min?: Maybe<User_Min_Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** on conflict condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>
  _not?: Maybe<User_Bool_Exp>
  _or?: Maybe<Array<User_Bool_Exp>>
  activeState?: Maybe<User_Active_State_Enum_Bool_Exp>
  active_state?: Maybe<User_Active_State_Enum_Enum_Comparison_Exp>
  challengeEntries?: Maybe<Challenge_Entry_Bool_Exp>
  challengeEntryComments?: Maybe<Challenge_Entry_Comment_Bool_Exp>
  challengeFollows?: Maybe<Challenge_Follow_Bool_Exp>
  challengesCreated?: Maybe<Challenge_Group_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  identity?: Maybe<User_Identifiable_Info_Bool_Exp>
  identity_id?: Maybe<Uuid_Comparison_Exp>
  oauth_id?: Maybe<String_Comparison_Exp>
  private?: Maybe<User_Private_Bool_Exp>
  type?: Maybe<User_Type_Enum_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  userType?: Maybe<User_Type_Enum_Bool_Exp>
  username?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserAuth0IdKey = 'user_auth0_id_key',
  /** unique or primary key constraint */
  UserDisplayNameKey = 'user_display_name_key',
  /** unique or primary key constraint */
  UserIdKey = 'user_id_key',
  /** unique or primary key constraint */
  UserIdentityIdKey = 'user_identity_id_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
}

/** columns and relationships of "user_identifiable_info" */
export type User_Identifiable_Info = {
  __typename?: 'user_identifiable_info'
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  name: Scalars['String']
  /** An object relationship */
  private?: Maybe<User_Identifiable_Info_Private>
  profilePhoto?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: User
}

/** aggregated selection of "user_identifiable_info" */
export type User_Identifiable_Info_Aggregate = {
  __typename?: 'user_identifiable_info_aggregate'
  aggregate?: Maybe<User_Identifiable_Info_Aggregate_Fields>
  nodes: Array<User_Identifiable_Info>
}

/** aggregate fields of "user_identifiable_info" */
export type User_Identifiable_Info_Aggregate_Fields = {
  __typename?: 'user_identifiable_info_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Identifiable_Info_Max_Fields>
  min?: Maybe<User_Identifiable_Info_Min_Fields>
}

/** aggregate fields of "user_identifiable_info" */
export type User_Identifiable_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Identifiable_Info_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_identifiable_info". All fields are combined with a logical 'AND'. */
export type User_Identifiable_Info_Bool_Exp = {
  _and?: Maybe<Array<User_Identifiable_Info_Bool_Exp>>
  _not?: Maybe<User_Identifiable_Info_Bool_Exp>
  _or?: Maybe<Array<User_Identifiable_Info_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  private?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
  profilePhoto?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "user_identifiable_info" */
export enum User_Identifiable_Info_Constraint {
  /** unique or primary key constraint */
  UserIdentifiableInfoEmailKey = 'user_identifiable_info_email_key',
  /** unique or primary key constraint */
  UserIdentifiableInfoPkey = 'user_identifiable_info_pkey',
}

/** input type for inserting data into table "user_identifiable_info" */
export type User_Identifiable_Info_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  private?: Maybe<User_Identifiable_Info_Private_Obj_Rel_Insert_Input>
  profilePhoto?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Identifiable_Info_Max_Fields = {
  __typename?: 'user_identifiable_info_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  profilePhoto?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type User_Identifiable_Info_Min_Fields = {
  __typename?: 'user_identifiable_info_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  profilePhoto?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "user_identifiable_info" */
export type User_Identifiable_Info_Mutation_Response = {
  __typename?: 'user_identifiable_info_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Identifiable_Info>
}

/** input type for inserting object relation for remote table "user_identifiable_info" */
export type User_Identifiable_Info_Obj_Rel_Insert_Input = {
  data: User_Identifiable_Info_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<User_Identifiable_Info_On_Conflict>
}

/** on conflict condition type for table "user_identifiable_info" */
export type User_Identifiable_Info_On_Conflict = {
  constraint: User_Identifiable_Info_Constraint
  update_columns?: Array<User_Identifiable_Info_Update_Column>
  where?: Maybe<User_Identifiable_Info_Bool_Exp>
}

/** Ordering options when selecting data from "user_identifiable_info". */
export type User_Identifiable_Info_Order_By = {
  created_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  private?: Maybe<User_Identifiable_Info_Private_Order_By>
  profilePhoto?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
}

/** primary key columns input for table: user_identifiable_info */
export type User_Identifiable_Info_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** columns and relationships of "user_identifiable_info_private" */
export type User_Identifiable_Info_Private = {
  __typename?: 'user_identifiable_info_private'
  email?: Maybe<Scalars['String']>
  /** An object relationship */
  public?: Maybe<User_Identifiable_Info>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregated selection of "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Aggregate = {
  __typename?: 'user_identifiable_info_private_aggregate'
  aggregate?: Maybe<User_Identifiable_Info_Private_Aggregate_Fields>
  nodes: Array<User_Identifiable_Info_Private>
}

/** aggregate fields of "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Aggregate_Fields = {
  __typename?: 'user_identifiable_info_private_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Identifiable_Info_Private_Max_Fields>
  min?: Maybe<User_Identifiable_Info_Private_Min_Fields>
}

/** aggregate fields of "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Identifiable_Info_Private_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_identifiable_info_private". All fields are combined with a logical 'AND'. */
export type User_Identifiable_Info_Private_Bool_Exp = {
  _and?: Maybe<Array<User_Identifiable_Info_Private_Bool_Exp>>
  _not?: Maybe<User_Identifiable_Info_Private_Bool_Exp>
  _or?: Maybe<Array<User_Identifiable_Info_Private_Bool_Exp>>
  email?: Maybe<String_Comparison_Exp>
  public?: Maybe<User_Identifiable_Info_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** input type for inserting data into table "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Insert_Input = {
  email?: Maybe<Scalars['String']>
  public?: Maybe<User_Identifiable_Info_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Identifiable_Info_Private_Max_Fields = {
  __typename?: 'user_identifiable_info_private_max_fields'
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Identifiable_Info_Private_Min_Fields = {
  __typename?: 'user_identifiable_info_private_min_fields'
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Mutation_Response = {
  __typename?: 'user_identifiable_info_private_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Identifiable_Info_Private>
}

/** input type for inserting object relation for remote table "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Obj_Rel_Insert_Input = {
  data: User_Identifiable_Info_Private_Insert_Input
}

/** Ordering options when selecting data from "user_identifiable_info_private". */
export type User_Identifiable_Info_Private_Order_By = {
  email?: Maybe<Order_By>
  public?: Maybe<User_Identifiable_Info_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_identifiable_info_private" */
export enum User_Identifiable_Info_Private_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_identifiable_info_private" */
export type User_Identifiable_Info_Private_Set_Input = {
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** select columns of table "user_identifiable_info" */
export enum User_Identifiable_Info_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePhoto = 'profilePhoto',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_identifiable_info" */
export type User_Identifiable_Info_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  profilePhoto?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "user_identifiable_info" */
export enum User_Identifiable_Info_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePhoto = 'profilePhoto',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  activeState?: Maybe<User_Active_State_Enum_Obj_Rel_Insert_Input>
  active_state?: Maybe<User_Active_State_Enum_Enum>
  challengeEntries?: Maybe<Challenge_Entry_Arr_Rel_Insert_Input>
  challengeEntryComments?: Maybe<Challenge_Entry_Comment_Arr_Rel_Insert_Input>
  challengeFollows?: Maybe<Challenge_Follow_Arr_Rel_Insert_Input>
  challengesCreated?: Maybe<Challenge_Group_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity?: Maybe<User_Identifiable_Info_Obj_Rel_Insert_Input>
  identity_id?: Maybe<Scalars['uuid']>
  oauth_id?: Maybe<Scalars['String']>
  private?: Maybe<User_Private_Obj_Rel_Insert_Input>
  type?: Maybe<User_Type_Enum_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  userType?: Maybe<User_Type_Enum_Obj_Rel_Insert_Input>
  username?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity_id?: Maybe<Scalars['uuid']>
  oauth_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  username?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  identity_id?: Maybe<Order_By>
  oauth_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity_id?: Maybe<Scalars['uuid']>
  oauth_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  username?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  identity_id?: Maybe<Order_By>
  oauth_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: Maybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  activeState?: Maybe<User_Active_State_Enum_Order_By>
  active_state?: Maybe<Order_By>
  challengeEntries_aggregate?: Maybe<Challenge_Entry_Aggregate_Order_By>
  challengeEntryComments_aggregate?: Maybe<Challenge_Entry_Comment_Aggregate_Order_By>
  challengeFollows_aggregate?: Maybe<Challenge_Follow_Aggregate_Order_By>
  challengesCreated_aggregate?: Maybe<Challenge_Group_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  identity?: Maybe<User_Identifiable_Info_Order_By>
  identity_id?: Maybe<Order_By>
  oauth_id?: Maybe<Order_By>
  private?: Maybe<User_Private_Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  userType?: Maybe<User_Type_Enum_Order_By>
  username?: Maybe<Order_By>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** columns and relationships of "user_private" */
export type User_Private = {
  __typename?: 'user_private'
  oauth_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregated selection of "user_private" */
export type User_Private_Aggregate = {
  __typename?: 'user_private_aggregate'
  aggregate?: Maybe<User_Private_Aggregate_Fields>
  nodes: Array<User_Private>
}

/** aggregate fields of "user_private" */
export type User_Private_Aggregate_Fields = {
  __typename?: 'user_private_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Private_Max_Fields>
  min?: Maybe<User_Private_Min_Fields>
}

/** aggregate fields of "user_private" */
export type User_Private_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Private_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_private". All fields are combined with a logical 'AND'. */
export type User_Private_Bool_Exp = {
  _and?: Maybe<Array<User_Private_Bool_Exp>>
  _not?: Maybe<User_Private_Bool_Exp>
  _or?: Maybe<Array<User_Private_Bool_Exp>>
  oauth_id?: Maybe<String_Comparison_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** input type for inserting data into table "user_private" */
export type User_Private_Insert_Input = {
  oauth_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Private_Max_Fields = {
  __typename?: 'user_private_max_fields'
  oauth_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Private_Min_Fields = {
  __typename?: 'user_private_min_fields'
  oauth_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_private" */
export type User_Private_Mutation_Response = {
  __typename?: 'user_private_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Private>
}

/** input type for inserting object relation for remote table "user_private" */
export type User_Private_Obj_Rel_Insert_Input = {
  data: User_Private_Insert_Input
}

/** Ordering options when selecting data from "user_private". */
export type User_Private_Order_By = {
  oauth_id?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_private" */
export enum User_Private_Select_Column {
  /** column name */
  OauthId = 'oauth_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_private" */
export type User_Private_Set_Input = {
  oauth_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['uuid']>
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  OauthId = 'oauth_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  active_state?: Maybe<User_Active_State_Enum_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity_id?: Maybe<Scalars['uuid']>
  oauth_id?: Maybe<Scalars['String']>
  type?: Maybe<User_Type_Enum_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  username?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_type_enum" */
export type User_Type_Enum = {
  __typename?: 'user_type_enum'
  description?: Maybe<Scalars['String']>
  type: Scalars['String']
}

/** aggregated selection of "user_type_enum" */
export type User_Type_Enum_Aggregate = {
  __typename?: 'user_type_enum_aggregate'
  aggregate?: Maybe<User_Type_Enum_Aggregate_Fields>
  nodes: Array<User_Type_Enum>
}

/** aggregate fields of "user_type_enum" */
export type User_Type_Enum_Aggregate_Fields = {
  __typename?: 'user_type_enum_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Type_Enum_Max_Fields>
  min?: Maybe<User_Type_Enum_Min_Fields>
}

/** aggregate fields of "user_type_enum" */
export type User_Type_Enum_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Type_Enum_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_type_enum". All fields are combined with a logical 'AND'. */
export type User_Type_Enum_Bool_Exp = {
  _and?: Maybe<Array<User_Type_Enum_Bool_Exp>>
  _not?: Maybe<User_Type_Enum_Bool_Exp>
  _or?: Maybe<Array<User_Type_Enum_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_type_enum" */
export enum User_Type_Enum_Constraint {
  /** unique or primary key constraint */
  UserTypeEnumPkey = 'user_type_enum_pkey',
}

export enum User_Type_Enum_Enum {
  Gym = 'GYM',
  Person = 'PERSON',
}

/** Boolean expression to compare columns of type "user_type_enum_enum". All fields are combined with logical 'AND'. */
export type User_Type_Enum_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Type_Enum_Enum>
  _in?: Maybe<Array<User_Type_Enum_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Type_Enum_Enum>
  _nin?: Maybe<Array<User_Type_Enum_Enum>>
}

/** input type for inserting data into table "user_type_enum" */
export type User_Type_Enum_Insert_Input = {
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Type_Enum_Max_Fields = {
  __typename?: 'user_type_enum_max_fields'
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Type_Enum_Min_Fields = {
  __typename?: 'user_type_enum_min_fields'
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_type_enum" */
export type User_Type_Enum_Mutation_Response = {
  __typename?: 'user_type_enum_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Type_Enum>
}

/** input type for inserting object relation for remote table "user_type_enum" */
export type User_Type_Enum_Obj_Rel_Insert_Input = {
  data: User_Type_Enum_Insert_Input
  /** on conflict condition */
  on_conflict?: Maybe<User_Type_Enum_On_Conflict>
}

/** on conflict condition type for table "user_type_enum" */
export type User_Type_Enum_On_Conflict = {
  constraint: User_Type_Enum_Constraint
  update_columns?: Array<User_Type_Enum_Update_Column>
  where?: Maybe<User_Type_Enum_Bool_Exp>
}

/** Ordering options when selecting data from "user_type_enum". */
export type User_Type_Enum_Order_By = {
  description?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** primary key columns input for table: user_type_enum */
export type User_Type_Enum_Pk_Columns_Input = {
  type: Scalars['String']
}

/** select columns of table "user_type_enum" */
export enum User_Type_Enum_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "user_type_enum" */
export type User_Type_Enum_Set_Input = {
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** update columns of table "user_type_enum" */
export enum User_Type_Enum_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  ActiveState = 'active_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  OauthId = 'oauth_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
}

export type Username_Available_Args = {
  name?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>
  _gt?: Maybe<Scalars['uuid']>
  _gte?: Maybe<Scalars['uuid']>
  _in?: Maybe<Array<Scalars['uuid']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['uuid']>
  _lte?: Maybe<Scalars['uuid']>
  _neq?: Maybe<Scalars['uuid']>
  _nin?: Maybe<Array<Scalars['uuid']>>
}

export type ChallengeEntryBasicsFragment = {
  __typename?: 'challenge_entry'
  id: any
  userId?: Maybe<any>
  challengeId: any
  score: any
  scoreRank: number
  active_state: Challenge_Entry_Active_State_Enum_Enum
  anonUsername?: Maybe<string>
}

export type CreateChallengeEntryMutationVariables = Exact<{
  challengeId: Scalars['uuid']
  score: Scalars['jsonb']
  scoreRank: Scalars['Int']
  comment?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['uuid']>
  anonUsername?: Maybe<Scalars['String']>
}>

export type CreateChallengeEntryMutation = {
  __typename?: 'mutation_root'
  insert_challenge_entry_one?: Maybe<{
    __typename?: 'challenge_entry'
    id: any
    userId?: Maybe<any>
    challengeId: any
    score: any
    scoreRank: number
    active_state: Challenge_Entry_Active_State_Enum_Enum
    anonUsername?: Maybe<string>
  }>
}

export type EditChallengeEntryMutationVariables = Exact<{
  entryId: Scalars['uuid']
  score: Scalars['jsonb']
  scoreRank: Scalars['Int']
  comment?: Maybe<Scalars['String']>
}>

export type EditChallengeEntryMutation = {
  __typename?: 'mutation_root'
  update_challenge_entry_by_pk?: Maybe<{
    __typename?: 'challenge_entry'
    id: any
    userId?: Maybe<any>
    challengeId: any
    score: any
    scoreRank: number
    active_state: Challenge_Entry_Active_State_Enum_Enum
    anonUsername?: Maybe<string>
  }>
}

export type DeleteChallengeEntryMutationVariables = Exact<{
  entryId: Scalars['uuid']
  activeState?: Maybe<Challenge_Entry_Active_State_Enum_Enum>
}>

export type DeleteChallengeEntryMutation = {
  __typename?: 'mutation_root'
  update_challenge_entry_by_pk?: Maybe<{
    __typename?: 'challenge_entry'
    id: any
    userId?: Maybe<any>
    challengeId: any
    score: any
    scoreRank: number
    active_state: Challenge_Entry_Active_State_Enum_Enum
    anonUsername?: Maybe<string>
  }>
}

export type CreateChallengeEntryCommentMutationVariables = Exact<{
  userId: Scalars['uuid']
  entryId: Scalars['uuid']
  message: Scalars['String']
}>

export type CreateChallengeEntryCommentMutation = {
  __typename?: 'mutation_root'
  insert_challenge_entry_comment_one?: Maybe<{
    __typename?: 'challenge_entry_comment'
    id: any
    user_id: any
    entry_id: any
    message: string
  }>
}

export type DeleteChallengeEntryCommentMutationVariables = Exact<{
  commentId: Scalars['uuid']
}>

export type DeleteChallengeEntryCommentMutation = {
  __typename?: 'mutation_root'
  delete_challenge_entry_comment_by_pk?: Maybe<{ __typename?: 'challenge_entry_comment'; id: any }>
}

export type CreateChallengeGroupMutationVariables = Exact<{
  title: Scalars['String']
  description: Scalars['String']
  userId: Scalars['uuid']
  challenges: Challenge_Arr_Rel_Insert_Input
  startDate: Scalars['timestamp']
  endDate: Scalars['timestamp']
  scoreType: Scalars['String']
  frequency: Challenge_Group_Series_Frequency_Enum_Enum
}>

export type CreateChallengeGroupMutation = {
  __typename?: 'mutation_root'
  insert_challenge_group_one?: Maybe<{
    __typename?: 'challenge_group'
    id: any
    title: string
    description: string
    active_state: Challenge_Group_Active_State_Enum_Enum
    startDate: string
    endDate: string
    scoreType: string
    frequency: {
      __typename?: 'challenge_group_series_frequency'
      frequency: Challenge_Group_Series_Frequency_Enum_Enum
    }
    challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
    followers_aggregate: {
      __typename?: 'challenge_follow_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
    }
  }>
}

export type UpdateChallengeGroupMutationVariables = Exact<{
  challengeId: Scalars['uuid']
  title: Scalars['String']
  description: Scalars['String']
  startDate: Scalars['timestamp']
  endDate: Scalars['timestamp']
}>

export type UpdateChallengeGroupMutation = {
  __typename?: 'mutation_root'
  update_challenge_group_by_pk?: Maybe<{
    __typename?: 'challenge_group'
    id: any
    title: string
    description: string
    active_state: Challenge_Group_Active_State_Enum_Enum
    startDate: string
    endDate: string
    scoreType: string
    frequency: {
      __typename?: 'challenge_group_series_frequency'
      frequency: Challenge_Group_Series_Frequency_Enum_Enum
    }
    challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
    followers_aggregate: {
      __typename?: 'challenge_follow_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
    }
  }>
}

export type DeleteChallengeGroupMutationVariables = Exact<{
  challengeId: Scalars['uuid']
}>

export type DeleteChallengeGroupMutation = {
  __typename?: 'mutation_root'
  update_challenge_group_by_pk?: Maybe<{ __typename?: 'challenge_group'; id: any }>
}

export type CreateNextInChallengeSeriesMutationVariables = Exact<{
  frequency: Challenge_Group_Series_Frequency_Enum_Enum
  id?: Maybe<Scalars['Int']>
  challengeGroup: Challenge_Group_Insert_Input
}>

export type CreateNextInChallengeSeriesMutation = {
  __typename?: 'mutation_root'
  insert_challenge_group_series_frequency_one?: Maybe<{
    __typename?: 'challenge_group_series_frequency'
    id: number
    series: number
    frequency: Challenge_Group_Series_Frequency_Enum_Enum
    challengeGroup: {
      __typename?: 'challenge_group'
      id: any
      userId: any
      scoreType: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      title: string
      description: string
      startDate: string
      endDate: string
      challenges: Array<{
        __typename?: 'challenge'
        active_state: Challenge_Active_State_Enum_Enum
        title: string
        description?: Maybe<string>
      }>
    }
  }>
}

export type ChallengeGroupOverviewFragment = {
  __typename?: 'challenge_group'
  id: any
  title: string
  description: string
  active_state: Challenge_Group_Active_State_Enum_Enum
  startDate: string
  endDate: string
  scoreType: string
  frequency: {
    __typename?: 'challenge_group_series_frequency'
    frequency: Challenge_Group_Series_Frequency_Enum_Enum
  }
  challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
  followers_aggregate: {
    __typename?: 'challenge_follow_aggregate'
    aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
  }
}

export type CurrentUserChallengesFragment = {
  __typename?: 'user'
  challengeFollows: Array<{
    __typename?: 'challenge_follow'
    challengeGroup: {
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }
  }>
  challengesCreated: Array<{
    __typename?: 'challenge_group'
    id: any
    title: string
    description: string
    active_state: Challenge_Group_Active_State_Enum_Enum
    startDate: string
    endDate: string
    scoreType: string
    frequency: {
      __typename?: 'challenge_group_series_frequency'
      frequency: Challenge_Group_Series_Frequency_Enum_Enum
    }
    challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
    followers_aggregate: {
      __typename?: 'challenge_follow_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
    }
  }>
}

export type ChallengeEntriesFragment = {
  __typename?: 'challenge_entry'
  id: any
  score: any
  created_at?: Maybe<any>
  scoreRank: number
  comment?: Maybe<string>
  anonUsername?: Maybe<string>
  user?: Maybe<{
    __typename?: 'user'
    id: any
    username: string
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      name: string
      profilePhoto?: Maybe<string>
    }>
  }>
}

export type ChallengeLeaderboardWithEntriesFragment = {
  __typename?: 'challenge'
  id: any
  title: string
  challengeEntries: Array<{
    __typename?: 'challenge_entry'
    id: any
    score: any
    created_at?: Maybe<any>
    scoreRank: number
    comment?: Maybe<string>
    anonUsername?: Maybe<string>
    user?: Maybe<{
      __typename?: 'user'
      id: any
      username: string
      identity?: Maybe<{
        __typename?: 'user_identifiable_info'
        name: string
        profilePhoto?: Maybe<string>
      }>
    }>
  }>
}

export type ChallengeCommentFragment = {
  __typename?: 'challenge_entry_comment'
  id: any
  message: string
  created_at: any
  user: {
    __typename?: 'user'
    id: any
    username: string
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      name: string
      profilePhoto?: Maybe<string>
    }>
  }
}

export type FetchCurrentChallengesQueryVariables = Exact<{
  userId: Scalars['uuid']
  groupWhere?: Maybe<Challenge_Group_Bool_Exp>
}>

export type FetchCurrentChallengesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengeFollows: Array<{
      __typename?: 'challenge_follow'
      challengeGroup: {
        __typename?: 'challenge_group'
        id: any
        title: string
        description: string
        active_state: Challenge_Group_Active_State_Enum_Enum
        startDate: string
        endDate: string
        scoreType: string
        frequency: {
          __typename?: 'challenge_group_series_frequency'
          frequency: Challenge_Group_Series_Frequency_Enum_Enum
        }
        challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
        followers_aggregate: {
          __typename?: 'challenge_follow_aggregate'
          aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
        }
      }
    }>
    challengesCreated: Array<{
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }>
  }>
}

export type FetchPastChallengesQueryVariables = Exact<{
  userId: Scalars['uuid']
  groupWhere?: Maybe<Challenge_Group_Bool_Exp>
}>

export type FetchPastChallengesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengeFollows: Array<{
      __typename?: 'challenge_follow'
      challengeGroup: {
        __typename?: 'challenge_group'
        id: any
        title: string
        description: string
        active_state: Challenge_Group_Active_State_Enum_Enum
        startDate: string
        endDate: string
        scoreType: string
        frequency: {
          __typename?: 'challenge_group_series_frequency'
          frequency: Challenge_Group_Series_Frequency_Enum_Enum
        }
        challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
        followers_aggregate: {
          __typename?: 'challenge_follow_aggregate'
          aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
        }
      }
    }>
    challengesCreated: Array<{
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }>
  }>
}

export type FetchUpcomingChallengesQueryVariables = Exact<{
  userId: Scalars['uuid']
  groupWhere?: Maybe<Challenge_Group_Bool_Exp>
}>

export type FetchUpcomingChallengesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengeFollows: Array<{
      __typename?: 'challenge_follow'
      challengeGroup: {
        __typename?: 'challenge_group'
        id: any
        title: string
        description: string
        active_state: Challenge_Group_Active_State_Enum_Enum
        startDate: string
        endDate: string
        scoreType: string
        frequency: {
          __typename?: 'challenge_group_series_frequency'
          frequency: Challenge_Group_Series_Frequency_Enum_Enum
        }
        challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
        followers_aggregate: {
          __typename?: 'challenge_follow_aggregate'
          aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
        }
      }
    }>
    challengesCreated: Array<{
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }>
  }>
}

export type FollowedChallengesQueryVariables = Exact<{
  username: Scalars['String']
  groupWhere?: Maybe<Challenge_Group_Bool_Exp>
}>

export type FollowedChallengesQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    challengeFollows_aggregate: {
      __typename?: 'challenge_follow_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
    }
    challengesCreated_aggregate: {
      __typename?: 'challenge_group_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_group_aggregate_fields'; count: number }>
    }
  }>
}

export type FetchChallengeLeaderboardQueryVariables = Exact<{
  challengeId: Scalars['uuid']
}>

export type FetchChallengeLeaderboardQuery = {
  __typename?: 'query_root'
  challenge_by_pk?: Maybe<{
    __typename?: 'challenge'
    id: any
    title: string
    challengeGroup?: Maybe<{ __typename?: 'challenge_group'; id: any; title: string }>
    challengeEntries: Array<{
      __typename?: 'challenge_entry'
      id: any
      score: any
      created_at?: Maybe<any>
      scoreRank: number
      comment?: Maybe<string>
      anonUsername?: Maybe<string>
      user?: Maybe<{
        __typename?: 'user'
        id: any
        username: string
        identity?: Maybe<{
          __typename?: 'user_identifiable_info'
          name: string
          profilePhoto?: Maybe<string>
        }>
      }>
    }>
  }>
}

export type FetchChallengeGroupLeaderboardsQueryVariables = Exact<{
  challengeGroupId: Scalars['uuid']
}>

export type FetchChallengeGroupLeaderboardsQuery = {
  __typename?: 'query_root'
  challenge_group: Array<{
    __typename?: 'challenge_group'
    id: any
    title: string
    description: string
    active_state: Challenge_Group_Active_State_Enum_Enum
    startDate: string
    endDate: string
    scoreType: string
    challenges: Array<{
      __typename?: 'challenge'
      description?: Maybe<string>
      title: string
      id: any
      challengeEntries: Array<{
        __typename?: 'challenge_entry'
        id: any
        score: any
        created_at?: Maybe<any>
        scoreRank: number
        comment?: Maybe<string>
        anonUsername?: Maybe<string>
        user?: Maybe<{
          __typename?: 'user'
          id: any
          username: string
          identity?: Maybe<{
            __typename?: 'user_identifiable_info'
            name: string
            profilePhoto?: Maybe<string>
          }>
        }>
      }>
    }>
    user: {
      __typename?: 'user'
      id: any
      username: string
      identity?: Maybe<{
        __typename?: 'user_identifiable_info'
        name: string
        profilePhoto?: Maybe<string>
      }>
    }
    frequency: {
      __typename?: 'challenge_group_series_frequency'
      frequency: Challenge_Group_Series_Frequency_Enum_Enum
    }
    followers_aggregate: {
      __typename?: 'challenge_follow_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
    }
  }>
}

export type FetchChallengeGroupAddScoreQueryVariables = Exact<{
  challengeGroupId: Scalars['uuid']
}>

export type FetchChallengeGroupAddScoreQuery = {
  __typename?: 'query_root'
  challenge_group_by_pk?: Maybe<{
    __typename?: 'challenge_group'
    id: any
    scoreType: string
    user: { __typename?: 'user'; id: any }
    challenges: Array<{ __typename?: 'challenge'; title: string; id: any }>
  }>
}

export type FetchRenewingChallengeSeriesQueryVariables = Exact<{ [key: string]: never }>

export type FetchRenewingChallengeSeriesQuery = {
  __typename?: 'query_root'
  series_missing: Array<{
    __typename?: 'challenge_group_series_frequency'
    id: number
    frequency: Challenge_Group_Series_Frequency_Enum_Enum
    series: number
    challengeGroup: {
      __typename?: 'challenge_group'
      id: any
      userId: any
      scoreType: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      title: string
      description: string
      startDate: string
      endDate: string
      challenges: Array<{
        __typename?: 'challenge'
        active_state: Challenge_Active_State_Enum_Enum
        title: string
        description?: Maybe<string>
      }>
    }
  }>
}

export type FetchAdditionalDetailsForAnEntryQueryVariables = Exact<{
  entryId: Scalars['uuid']
  userId?: Maybe<Scalars['uuid']>
  anonUsername?: Maybe<Scalars['String']>
  challengeId: Scalars['uuid']
}>

export type FetchAdditionalDetailsForAnEntryQuery = {
  __typename?: 'query_root'
  challenge_entry_by_pk?: Maybe<{
    __typename?: 'challenge_entry'
    comment?: Maybe<string>
    score: any
    created_at?: Maybe<any>
    anonUsername?: Maybe<string>
    user?: Maybe<{
      __typename?: 'user'
      id: any
      username: string
      identity?: Maybe<{ __typename?: 'user_identifiable_info'; name: string }>
    }>
    entryComments: Array<{
      __typename?: 'challenge_entry_comment'
      id: any
      message: string
      created_at: any
      user: {
        __typename?: 'user'
        id: any
        username: string
        identity?: Maybe<{
          __typename?: 'user_identifiable_info'
          name: string
          profilePhoto?: Maybe<string>
        }>
      }
    }>
    challenge: {
      __typename?: 'challenge'
      title: string
      challengeGroup?: Maybe<{
        __typename?: 'challenge_group'
        title: string
        user: { __typename?: 'user'; id: any }
      }>
    }
  }>
  challenge_entry: Array<{
    __typename?: 'challenge_entry'
    id: any
    score: any
    created_at?: Maybe<any>
    scoreRank: number
    comment?: Maybe<string>
    anonUsername?: Maybe<string>
    user?: Maybe<{
      __typename?: 'user'
      id: any
      username: string
      identity?: Maybe<{
        __typename?: 'user_identifiable_info'
        name: string
        profilePhoto?: Maybe<string>
      }>
    }>
  }>
}

export type FetchUsersActiveCreatedChallengesQueryVariables = Exact<{
  userId: Scalars['uuid']
}>

export type FetchUsersActiveCreatedChallengesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengesCreated: Array<{
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }>
  }>
}

export type FetchUsersPastCreatedChallengesQueryVariables = Exact<{
  userId: Scalars['uuid']
}>

export type FetchUsersPastCreatedChallengesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengesCreated: Array<{
      __typename?: 'challenge_group'
      id: any
      title: string
      description: string
      active_state: Challenge_Group_Active_State_Enum_Enum
      startDate: string
      endDate: string
      scoreType: string
      frequency: {
        __typename?: 'challenge_group_series_frequency'
        frequency: Challenge_Group_Series_Frequency_Enum_Enum
      }
      challenges: Array<{ __typename?: 'challenge'; description?: Maybe<string>; title: string }>
      followers_aggregate: {
        __typename?: 'challenge_follow_aggregate'
        aggregate?: Maybe<{ __typename?: 'challenge_follow_aggregate_fields'; count: number }>
      }
    }>
  }>
}

export type CreateUserMutationVariables = Exact<{
  username: Scalars['String']
  name: Scalars['String']
  profilePhoto?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}>

export type CreateUserMutation = {
  __typename?: 'mutation_root'
  insert_user_one?: Maybe<{
    __typename?: 'user'
    id: any
    type: User_Type_Enum_Enum
    username: string
    created_at?: Maybe<any>
    activeState: { __typename?: 'user_active_state_enum'; state: string }
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      id: any
      name: string
      profilePhoto?: Maybe<string>
      created_at?: Maybe<any>
      private?: Maybe<{ __typename?: 'user_identifiable_info_private'; email?: Maybe<string> }>
    }>
  }>
}

export type UserFragment = {
  __typename?: 'user'
  id: any
  type: User_Type_Enum_Enum
  username: string
  created_at?: Maybe<any>
  activeState: { __typename?: 'user_active_state_enum'; state: string }
  identity?: Maybe<{
    __typename?: 'user_identifiable_info'
    id: any
    name: string
    profilePhoto?: Maybe<string>
    created_at?: Maybe<any>
    private?: Maybe<{ __typename?: 'user_identifiable_info_private'; email?: Maybe<string> }>
  }>
}

export type UserOverviewFragment = {
  __typename?: 'user'
  id: any
  username: string
  identity?: Maybe<{
    __typename?: 'user_identifiable_info'
    name: string
    profilePhoto?: Maybe<string>
  }>
}

export type UserByOAuthIdQueryVariables = Exact<{
  oauth_id: Scalars['String']
}>

export type UserByOAuthIdQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: any
    type: User_Type_Enum_Enum
    username: string
    created_at?: Maybe<any>
    activeState: { __typename?: 'user_active_state_enum'; state: string }
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      id: any
      name: string
      profilePhoto?: Maybe<string>
      created_at?: Maybe<any>
      private?: Maybe<{ __typename?: 'user_identifiable_info_private'; email?: Maybe<string> }>
    }>
  }>
}

export type UsernameAvailableQueryVariables = Exact<{
  username: Scalars['String']
}>

export type UsernameAvailableQuery = {
  __typename?: 'query_root'
  username_available: Array<{ __typename?: 'boolean'; boolean: boolean }>
}

export type FetchUserByNameQueryVariables = Exact<{
  name: Scalars['String']
}>

export type FetchUserByNameQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: any
    username: string
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      name: string
      profilePhoto?: Maybe<string>
    }>
  }>
}

export type FetchUserProfilePageDetailsQueryVariables = Exact<{
  userId: Scalars['uuid']
}>

export type FetchUserProfilePageDetailsQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    id: any
    username: string
    active_state: User_Active_State_Enum_Enum
    type: User_Type_Enum_Enum
    identity?: Maybe<{
      __typename?: 'user_identifiable_info'
      name: string
      profilePhoto?: Maybe<string>
    }>
    challengesCreated_aggregate: {
      __typename?: 'challenge_group_aggregate'
      aggregate?: Maybe<{ __typename?: 'challenge_group_aggregate_fields'; count: number }>
    }
    challengeEntries_aggregate: {
      __typename?: 'challenge_entry_aggregate'
      aggregate?: Maybe<{
        __typename?: 'challenge_entry_aggregate_fields'
        differentChallenges: number
        totalEntries: number
      }>
    }
  }>
}

export type FetchUsersEntriesQueryVariables = Exact<{
  userId: Scalars['uuid']
}>

export type FetchUsersEntriesQuery = {
  __typename?: 'query_root'
  user_by_pk?: Maybe<{
    __typename?: 'user'
    challengeEntries: Array<{
      __typename?: 'challenge_entry'
      id: any
      score: any
      created_at?: Maybe<any>
      challenge: {
        __typename?: 'challenge'
        challengeGroup?: Maybe<{
          __typename?: 'challenge_group'
          id: any
          title: string
          user: { __typename?: 'user'; id: any; username: string }
        }>
      }
    }>
  }>
}

export const ChallengeEntryBasicsFragmentDoc = gql`
  fragment ChallengeEntryBasics on challenge_entry {
    id
    userId
    challengeId
    score
    scoreRank
    active_state
    anonUsername
  }
`
export const ChallengeGroupOverviewFragmentDoc = gql`
  fragment ChallengeGroupOverview on challenge_group {
    id
    title
    description
    active_state
    startDate
    endDate
    scoreType
    frequency {
      frequency
    }
    challenges(where: { activeState: { state: { _eq: "VISIBLE" } } }) {
      description
      title
    }
    followers_aggregate {
      aggregate {
        count
      }
    }
  }
`
export const CurrentUserChallengesFragmentDoc = gql`
  fragment CurrentUserChallenges on user {
    challengeFollows(
      where: { challengeGroup: { _and: [{ user: { id: { _neq: $userId } } }, $groupWhere] } }
    ) {
      challengeGroup {
        ...ChallengeGroupOverview
      }
    }
    challengesCreated(where: $groupWhere) {
      ...ChallengeGroupOverview
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
`
export const UserOverviewFragmentDoc = gql`
  fragment UserOverview on user {
    id
    username
    identity {
      name
      profilePhoto
    }
  }
`
export const ChallengeEntriesFragmentDoc = gql`
  fragment ChallengeEntries on challenge_entry {
    id
    user {
      ...UserOverview
    }
    score
    created_at
    scoreRank
    comment
    anonUsername
  }
  ${UserOverviewFragmentDoc}
`
export const ChallengeLeaderboardWithEntriesFragmentDoc = gql`
  fragment ChallengeLeaderboardWithEntries on challenge {
    id
    title
    challengeEntries(
      order_by: { scoreRank: desc_nulls_last }
      where: { activeState: { state: { _eq: "ACTIVE" } } }
    ) {
      ...ChallengeEntries
    }
  }
  ${ChallengeEntriesFragmentDoc}
`
export const ChallengeCommentFragmentDoc = gql`
  fragment ChallengeComment on challenge_entry_comment {
    id
    message
    user {
      ...UserOverview
    }
    created_at
  }
  ${UserOverviewFragmentDoc}
`
export const UserFragmentDoc = gql`
  fragment User on user {
    activeState {
      state
    }
    id
    identity {
      private {
        email
      }
      id
      name
      profilePhoto
      created_at
    }
    type
    username
    created_at
  }
`
export const CreateChallengeEntryDocument = gql`
  mutation CreateChallengeEntry(
    $challengeId: uuid!
    $score: jsonb!
    $scoreRank: Int!
    $comment: String
    $userId: uuid
    $anonUsername: String
  ) {
    insert_challenge_entry_one(
      object: {
        challengeId: $challengeId
        userId: $userId
        score: $score
        scoreRank: $scoreRank
        comment: $comment
        anonUsername: $anonUsername
      }
    ) {
      ...ChallengeEntryBasics
    }
  }
  ${ChallengeEntryBasicsFragmentDoc}
`
export type CreateChallengeEntryMutationFn = Apollo.MutationFunction<
  CreateChallengeEntryMutation,
  CreateChallengeEntryMutationVariables
>

/**
 * __useCreateChallengeEntryMutation__
 *
 * To run a mutation, you first call `useCreateChallengeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeEntryMutation, { data, loading, error }] = useCreateChallengeEntryMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      score: // value for 'score'
 *      scoreRank: // value for 'scoreRank'
 *      comment: // value for 'comment'
 *      userId: // value for 'userId'
 *      anonUsername: // value for 'anonUsername'
 *   },
 * });
 */
export function useCreateChallengeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChallengeEntryMutation,
    CreateChallengeEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChallengeEntryMutation, CreateChallengeEntryMutationVariables>(
    CreateChallengeEntryDocument,
    options,
  )
}
export type CreateChallengeEntryMutationHookResult = ReturnType<
  typeof useCreateChallengeEntryMutation
>
export type CreateChallengeEntryMutationResult = Apollo.MutationResult<CreateChallengeEntryMutation>
export type CreateChallengeEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateChallengeEntryMutation,
  CreateChallengeEntryMutationVariables
>
export const EditChallengeEntryDocument = gql`
  mutation EditChallengeEntry($entryId: uuid!, $score: jsonb!, $scoreRank: Int!, $comment: String) {
    update_challenge_entry_by_pk(
      pk_columns: { id: $entryId }
      _set: { score: $score, scoreRank: $scoreRank, comment: $comment }
    ) {
      ...ChallengeEntryBasics
    }
  }
  ${ChallengeEntryBasicsFragmentDoc}
`
export type EditChallengeEntryMutationFn = Apollo.MutationFunction<
  EditChallengeEntryMutation,
  EditChallengeEntryMutationVariables
>

/**
 * __useEditChallengeEntryMutation__
 *
 * To run a mutation, you first call `useEditChallengeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChallengeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChallengeEntryMutation, { data, loading, error }] = useEditChallengeEntryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      score: // value for 'score'
 *      scoreRank: // value for 'scoreRank'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEditChallengeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditChallengeEntryMutation,
    EditChallengeEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditChallengeEntryMutation, EditChallengeEntryMutationVariables>(
    EditChallengeEntryDocument,
    options,
  )
}
export type EditChallengeEntryMutationHookResult = ReturnType<typeof useEditChallengeEntryMutation>
export type EditChallengeEntryMutationResult = Apollo.MutationResult<EditChallengeEntryMutation>
export type EditChallengeEntryMutationOptions = Apollo.BaseMutationOptions<
  EditChallengeEntryMutation,
  EditChallengeEntryMutationVariables
>
export const DeleteChallengeEntryDocument = gql`
  mutation DeleteChallengeEntry(
    $entryId: uuid!
    $activeState: challenge_entry_active_state_enum_enum
  ) {
    update_challenge_entry_by_pk(
      pk_columns: { id: $entryId }
      _set: { active_state: $activeState }
    ) {
      ...ChallengeEntryBasics
    }
  }
  ${ChallengeEntryBasicsFragmentDoc}
`
export type DeleteChallengeEntryMutationFn = Apollo.MutationFunction<
  DeleteChallengeEntryMutation,
  DeleteChallengeEntryMutationVariables
>

/**
 * __useDeleteChallengeEntryMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeEntryMutation, { data, loading, error }] = useDeleteChallengeEntryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      activeState: // value for 'activeState'
 *   },
 * });
 */
export function useDeleteChallengeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChallengeEntryMutation,
    DeleteChallengeEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChallengeEntryMutation, DeleteChallengeEntryMutationVariables>(
    DeleteChallengeEntryDocument,
    options,
  )
}
export type DeleteChallengeEntryMutationHookResult = ReturnType<
  typeof useDeleteChallengeEntryMutation
>
export type DeleteChallengeEntryMutationResult = Apollo.MutationResult<DeleteChallengeEntryMutation>
export type DeleteChallengeEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteChallengeEntryMutation,
  DeleteChallengeEntryMutationVariables
>
export const CreateChallengeEntryCommentDocument = gql`
  mutation CreateChallengeEntryComment($userId: uuid!, $entryId: uuid!, $message: String!) {
    insert_challenge_entry_comment_one(
      object: { user_id: $userId, entry_id: $entryId, message: $message }
    ) {
      id
      user_id
      entry_id
      message
    }
  }
`
export type CreateChallengeEntryCommentMutationFn = Apollo.MutationFunction<
  CreateChallengeEntryCommentMutation,
  CreateChallengeEntryCommentMutationVariables
>

/**
 * __useCreateChallengeEntryCommentMutation__
 *
 * To run a mutation, you first call `useCreateChallengeEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeEntryCommentMutation, { data, loading, error }] = useCreateChallengeEntryCommentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      entryId: // value for 'entryId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateChallengeEntryCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChallengeEntryCommentMutation,
    CreateChallengeEntryCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChallengeEntryCommentMutation,
    CreateChallengeEntryCommentMutationVariables
  >(CreateChallengeEntryCommentDocument, options)
}
export type CreateChallengeEntryCommentMutationHookResult = ReturnType<
  typeof useCreateChallengeEntryCommentMutation
>
export type CreateChallengeEntryCommentMutationResult =
  Apollo.MutationResult<CreateChallengeEntryCommentMutation>
export type CreateChallengeEntryCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateChallengeEntryCommentMutation,
  CreateChallengeEntryCommentMutationVariables
>
export const DeleteChallengeEntryCommentDocument = gql`
  mutation DeleteChallengeEntryComment($commentId: uuid!) {
    delete_challenge_entry_comment_by_pk(id: $commentId) {
      id
    }
  }
`
export type DeleteChallengeEntryCommentMutationFn = Apollo.MutationFunction<
  DeleteChallengeEntryCommentMutation,
  DeleteChallengeEntryCommentMutationVariables
>

/**
 * __useDeleteChallengeEntryCommentMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeEntryCommentMutation, { data, loading, error }] = useDeleteChallengeEntryCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteChallengeEntryCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChallengeEntryCommentMutation,
    DeleteChallengeEntryCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteChallengeEntryCommentMutation,
    DeleteChallengeEntryCommentMutationVariables
  >(DeleteChallengeEntryCommentDocument, options)
}
export type DeleteChallengeEntryCommentMutationHookResult = ReturnType<
  typeof useDeleteChallengeEntryCommentMutation
>
export type DeleteChallengeEntryCommentMutationResult =
  Apollo.MutationResult<DeleteChallengeEntryCommentMutation>
export type DeleteChallengeEntryCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteChallengeEntryCommentMutation,
  DeleteChallengeEntryCommentMutationVariables
>
export const CreateChallengeGroupDocument = gql`
  mutation CreateChallengeGroup(
    $title: String!
    $description: String!
    $userId: uuid!
    $challenges: challenge_arr_rel_insert_input!
    $startDate: timestamp!
    $endDate: timestamp!
    $scoreType: String!
    $frequency: challenge_group_series_frequency_enum_enum!
  ) {
    insert_challenge_group_one(
      object: {
        title: $title
        description: $description
        userId: $userId
        challenges: $challenges
        startDate: $startDate
        endDate: $endDate
        scoreType: $scoreType
        active_state: VISIBLE
        frequency: { data: { frequency: $frequency } }
      }
    ) {
      ...ChallengeGroupOverview
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
`
export type CreateChallengeGroupMutationFn = Apollo.MutationFunction<
  CreateChallengeGroupMutation,
  CreateChallengeGroupMutationVariables
>

/**
 * __useCreateChallengeGroupMutation__
 *
 * To run a mutation, you first call `useCreateChallengeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeGroupMutation, { data, loading, error }] = useCreateChallengeGroupMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      userId: // value for 'userId'
 *      challenges: // value for 'challenges'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      scoreType: // value for 'scoreType'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useCreateChallengeGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChallengeGroupMutation,
    CreateChallengeGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChallengeGroupMutation, CreateChallengeGroupMutationVariables>(
    CreateChallengeGroupDocument,
    options,
  )
}
export type CreateChallengeGroupMutationHookResult = ReturnType<
  typeof useCreateChallengeGroupMutation
>
export type CreateChallengeGroupMutationResult = Apollo.MutationResult<CreateChallengeGroupMutation>
export type CreateChallengeGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateChallengeGroupMutation,
  CreateChallengeGroupMutationVariables
>
export const UpdateChallengeGroupDocument = gql`
  mutation UpdateChallengeGroup(
    $challengeId: uuid!
    $title: String!
    $description: String!
    $startDate: timestamp!
    $endDate: timestamp!
  ) {
    update_challenge_group_by_pk(
      pk_columns: { id: $challengeId }
      _set: { title: $title, description: $description, startDate: $startDate, endDate: $endDate }
    ) {
      ...ChallengeGroupOverview
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
`
export type UpdateChallengeGroupMutationFn = Apollo.MutationFunction<
  UpdateChallengeGroupMutation,
  UpdateChallengeGroupMutationVariables
>

/**
 * __useUpdateChallengeGroupMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeGroupMutation, { data, loading, error }] = useUpdateChallengeGroupMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateChallengeGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChallengeGroupMutation,
    UpdateChallengeGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateChallengeGroupMutation, UpdateChallengeGroupMutationVariables>(
    UpdateChallengeGroupDocument,
    options,
  )
}
export type UpdateChallengeGroupMutationHookResult = ReturnType<
  typeof useUpdateChallengeGroupMutation
>
export type UpdateChallengeGroupMutationResult = Apollo.MutationResult<UpdateChallengeGroupMutation>
export type UpdateChallengeGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateChallengeGroupMutation,
  UpdateChallengeGroupMutationVariables
>
export const DeleteChallengeGroupDocument = gql`
  mutation DeleteChallengeGroup($challengeId: uuid!) {
    update_challenge_group_by_pk(
      pk_columns: { id: $challengeId }
      _set: { active_state: DELETED }
    ) {
      id
    }
  }
`
export type DeleteChallengeGroupMutationFn = Apollo.MutationFunction<
  DeleteChallengeGroupMutation,
  DeleteChallengeGroupMutationVariables
>

/**
 * __useDeleteChallengeGroupMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeGroupMutation, { data, loading, error }] = useDeleteChallengeGroupMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useDeleteChallengeGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChallengeGroupMutation,
    DeleteChallengeGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChallengeGroupMutation, DeleteChallengeGroupMutationVariables>(
    DeleteChallengeGroupDocument,
    options,
  )
}
export type DeleteChallengeGroupMutationHookResult = ReturnType<
  typeof useDeleteChallengeGroupMutation
>
export type DeleteChallengeGroupMutationResult = Apollo.MutationResult<DeleteChallengeGroupMutation>
export type DeleteChallengeGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteChallengeGroupMutation,
  DeleteChallengeGroupMutationVariables
>
export const CreateNextInChallengeSeriesDocument = gql`
  mutation CreateNextInChallengeSeries(
    $frequency: challenge_group_series_frequency_enum_enum!
    $id: Int
    $challengeGroup: challenge_group_insert_input!
  ) {
    insert_challenge_group_series_frequency_one(
      object: { frequency: $frequency, id: $id, challengeGroup: { data: $challengeGroup } }
    ) {
      id
      series
      frequency
      challengeGroup {
        id
        userId
        scoreType
        active_state
        title
        description
        challenges {
          active_state
          title
          description
        }
        startDate
        endDate
      }
    }
  }
`
export type CreateNextInChallengeSeriesMutationFn = Apollo.MutationFunction<
  CreateNextInChallengeSeriesMutation,
  CreateNextInChallengeSeriesMutationVariables
>

/**
 * __useCreateNextInChallengeSeriesMutation__
 *
 * To run a mutation, you first call `useCreateNextInChallengeSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextInChallengeSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextInChallengeSeriesMutation, { data, loading, error }] = useCreateNextInChallengeSeriesMutation({
 *   variables: {
 *      frequency: // value for 'frequency'
 *      id: // value for 'id'
 *      challengeGroup: // value for 'challengeGroup'
 *   },
 * });
 */
export function useCreateNextInChallengeSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNextInChallengeSeriesMutation,
    CreateNextInChallengeSeriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateNextInChallengeSeriesMutation,
    CreateNextInChallengeSeriesMutationVariables
  >(CreateNextInChallengeSeriesDocument, options)
}
export type CreateNextInChallengeSeriesMutationHookResult = ReturnType<
  typeof useCreateNextInChallengeSeriesMutation
>
export type CreateNextInChallengeSeriesMutationResult =
  Apollo.MutationResult<CreateNextInChallengeSeriesMutation>
export type CreateNextInChallengeSeriesMutationOptions = Apollo.BaseMutationOptions<
  CreateNextInChallengeSeriesMutation,
  CreateNextInChallengeSeriesMutationVariables
>
export const FetchCurrentChallengesDocument = gql`
  query FetchCurrentChallenges(
    $userId: uuid!
    $groupWhere: challenge_group_bool_exp = {
      startDate: { _lt: "now()" }
      endDate: { _gt: "now()" }
      active_state: { _neq: DELETED }
    }
  ) {
    user_by_pk(id: $userId) {
      ...CurrentUserChallenges
    }
  }
  ${CurrentUserChallengesFragmentDoc}
`

/**
 * __useFetchCurrentChallengesQuery__
 *
 * To run a query within a React component, call `useFetchCurrentChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentChallengesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupWhere: // value for 'groupWhere'
 *   },
 * });
 */
export function useFetchCurrentChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCurrentChallengesQuery,
    FetchCurrentChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchCurrentChallengesQuery, FetchCurrentChallengesQueryVariables>(
    FetchCurrentChallengesDocument,
    options,
  )
}
export function useFetchCurrentChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCurrentChallengesQuery,
    FetchCurrentChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchCurrentChallengesQuery, FetchCurrentChallengesQueryVariables>(
    FetchCurrentChallengesDocument,
    options,
  )
}
export type FetchCurrentChallengesQueryHookResult = ReturnType<
  typeof useFetchCurrentChallengesQuery
>
export type FetchCurrentChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchCurrentChallengesLazyQuery
>
export type FetchCurrentChallengesQueryResult = Apollo.QueryResult<
  FetchCurrentChallengesQuery,
  FetchCurrentChallengesQueryVariables
>
export const FetchPastChallengesDocument = gql`
  query FetchPastChallenges(
    $userId: uuid!
    $groupWhere: challenge_group_bool_exp = {
      endDate: { _lt: "now()" }
      active_state: { _neq: DELETED }
    }
  ) {
    user_by_pk(id: $userId) {
      ...CurrentUserChallenges
    }
  }
  ${CurrentUserChallengesFragmentDoc}
`

/**
 * __useFetchPastChallengesQuery__
 *
 * To run a query within a React component, call `useFetchPastChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPastChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPastChallengesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupWhere: // value for 'groupWhere'
 *   },
 * });
 */
export function useFetchPastChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPastChallengesQuery, FetchPastChallengesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPastChallengesQuery, FetchPastChallengesQueryVariables>(
    FetchPastChallengesDocument,
    options,
  )
}
export function useFetchPastChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPastChallengesQuery,
    FetchPastChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPastChallengesQuery, FetchPastChallengesQueryVariables>(
    FetchPastChallengesDocument,
    options,
  )
}
export type FetchPastChallengesQueryHookResult = ReturnType<typeof useFetchPastChallengesQuery>
export type FetchPastChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchPastChallengesLazyQuery
>
export type FetchPastChallengesQueryResult = Apollo.QueryResult<
  FetchPastChallengesQuery,
  FetchPastChallengesQueryVariables
>
export const FetchUpcomingChallengesDocument = gql`
  query FetchUpcomingChallenges(
    $userId: uuid!
    $groupWhere: challenge_group_bool_exp = {
      startDate: { _gt: "now()" }
      active_state: { _neq: DELETED }
    }
  ) {
    user_by_pk(id: $userId) {
      ...CurrentUserChallenges
    }
  }
  ${CurrentUserChallengesFragmentDoc}
`

/**
 * __useFetchUpcomingChallengesQuery__
 *
 * To run a query within a React component, call `useFetchUpcomingChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUpcomingChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUpcomingChallengesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupWhere: // value for 'groupWhere'
 *   },
 * });
 */
export function useFetchUpcomingChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUpcomingChallengesQuery,
    FetchUpcomingChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchUpcomingChallengesQuery, FetchUpcomingChallengesQueryVariables>(
    FetchUpcomingChallengesDocument,
    options,
  )
}
export function useFetchUpcomingChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUpcomingChallengesQuery,
    FetchUpcomingChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchUpcomingChallengesQuery, FetchUpcomingChallengesQueryVariables>(
    FetchUpcomingChallengesDocument,
    options,
  )
}
export type FetchUpcomingChallengesQueryHookResult = ReturnType<
  typeof useFetchUpcomingChallengesQuery
>
export type FetchUpcomingChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchUpcomingChallengesLazyQuery
>
export type FetchUpcomingChallengesQueryResult = Apollo.QueryResult<
  FetchUpcomingChallengesQuery,
  FetchUpcomingChallengesQueryVariables
>
export const FollowedChallengesDocument = gql`
  query FollowedChallenges(
    $username: String!
    $groupWhere: challenge_group_bool_exp = {
      startDate: { _lt: "now()" }
      endDate: { _gt: "now()" }
      active_state: { _eq: VISIBLE }
    }
  ) {
    user(where: { username: { _eq: $username } }, limit: 1) {
      challengeFollows_aggregate(where: { challengeGroup: $groupWhere }) {
        aggregate {
          count
        }
      }
      challengesCreated_aggregate(where: $groupWhere) {
        aggregate {
          count
        }
      }
    }
  }
`

/**
 * __useFollowedChallengesQuery__
 *
 * To run a query within a React component, call `useFollowedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowedChallengesQuery({
 *   variables: {
 *      username: // value for 'username'
 *      groupWhere: // value for 'groupWhere'
 *   },
 * });
 */
export function useFollowedChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<FollowedChallengesQuery, FollowedChallengesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FollowedChallengesQuery, FollowedChallengesQueryVariables>(
    FollowedChallengesDocument,
    options,
  )
}
export function useFollowedChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FollowedChallengesQuery,
    FollowedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FollowedChallengesQuery, FollowedChallengesQueryVariables>(
    FollowedChallengesDocument,
    options,
  )
}
export type FollowedChallengesQueryHookResult = ReturnType<typeof useFollowedChallengesQuery>
export type FollowedChallengesLazyQueryHookResult = ReturnType<
  typeof useFollowedChallengesLazyQuery
>
export type FollowedChallengesQueryResult = Apollo.QueryResult<
  FollowedChallengesQuery,
  FollowedChallengesQueryVariables
>
export const FetchChallengeLeaderboardDocument = gql`
  query FetchChallengeLeaderboard($challengeId: uuid!) {
    challenge_by_pk(id: $challengeId) {
      ...ChallengeLeaderboardWithEntries
      challengeGroup {
        id
        title
      }
    }
  }
  ${ChallengeLeaderboardWithEntriesFragmentDoc}
`

/**
 * __useFetchChallengeLeaderboardQuery__
 *
 * To run a query within a React component, call `useFetchChallengeLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChallengeLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChallengeLeaderboardQuery({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useFetchChallengeLeaderboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchChallengeLeaderboardQuery,
    FetchChallengeLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchChallengeLeaderboardQuery, FetchChallengeLeaderboardQueryVariables>(
    FetchChallengeLeaderboardDocument,
    options,
  )
}
export function useFetchChallengeLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchChallengeLeaderboardQuery,
    FetchChallengeLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchChallengeLeaderboardQuery,
    FetchChallengeLeaderboardQueryVariables
  >(FetchChallengeLeaderboardDocument, options)
}
export type FetchChallengeLeaderboardQueryHookResult = ReturnType<
  typeof useFetchChallengeLeaderboardQuery
>
export type FetchChallengeLeaderboardLazyQueryHookResult = ReturnType<
  typeof useFetchChallengeLeaderboardLazyQuery
>
export type FetchChallengeLeaderboardQueryResult = Apollo.QueryResult<
  FetchChallengeLeaderboardQuery,
  FetchChallengeLeaderboardQueryVariables
>
export const FetchChallengeGroupLeaderboardsDocument = gql`
  query FetchChallengeGroupLeaderboards($challengeGroupId: uuid!) {
    challenge_group(
      where: {
        _and: [{ id: { _eq: $challengeGroupId } }, { active_state: { _in: [CREATING, VISIBLE] } }]
      }
      limit: 1
    ) {
      ...ChallengeGroupOverview
      challenges(where: { activeState: { state: { _eq: "VISIBLE" } } }) {
        ...ChallengeLeaderboardWithEntries
      }
      user {
        ...UserOverview
      }
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
  ${ChallengeLeaderboardWithEntriesFragmentDoc}
  ${UserOverviewFragmentDoc}
`

/**
 * __useFetchChallengeGroupLeaderboardsQuery__
 *
 * To run a query within a React component, call `useFetchChallengeGroupLeaderboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChallengeGroupLeaderboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChallengeGroupLeaderboardsQuery({
 *   variables: {
 *      challengeGroupId: // value for 'challengeGroupId'
 *   },
 * });
 */
export function useFetchChallengeGroupLeaderboardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchChallengeGroupLeaderboardsQuery,
    FetchChallengeGroupLeaderboardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchChallengeGroupLeaderboardsQuery,
    FetchChallengeGroupLeaderboardsQueryVariables
  >(FetchChallengeGroupLeaderboardsDocument, options)
}
export function useFetchChallengeGroupLeaderboardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchChallengeGroupLeaderboardsQuery,
    FetchChallengeGroupLeaderboardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchChallengeGroupLeaderboardsQuery,
    FetchChallengeGroupLeaderboardsQueryVariables
  >(FetchChallengeGroupLeaderboardsDocument, options)
}
export type FetchChallengeGroupLeaderboardsQueryHookResult = ReturnType<
  typeof useFetchChallengeGroupLeaderboardsQuery
>
export type FetchChallengeGroupLeaderboardsLazyQueryHookResult = ReturnType<
  typeof useFetchChallengeGroupLeaderboardsLazyQuery
>
export type FetchChallengeGroupLeaderboardsQueryResult = Apollo.QueryResult<
  FetchChallengeGroupLeaderboardsQuery,
  FetchChallengeGroupLeaderboardsQueryVariables
>
export const FetchChallengeGroupAddScoreDocument = gql`
  query FetchChallengeGroupAddScore($challengeGroupId: uuid!) {
    challenge_group_by_pk(id: $challengeGroupId) {
      id
      scoreType
      user {
        id
      }
      challenges(where: { activeState: { state: { _eq: "VISIBLE" } } }) {
        title
        id
      }
    }
  }
`

/**
 * __useFetchChallengeGroupAddScoreQuery__
 *
 * To run a query within a React component, call `useFetchChallengeGroupAddScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChallengeGroupAddScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChallengeGroupAddScoreQuery({
 *   variables: {
 *      challengeGroupId: // value for 'challengeGroupId'
 *   },
 * });
 */
export function useFetchChallengeGroupAddScoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchChallengeGroupAddScoreQuery,
    FetchChallengeGroupAddScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchChallengeGroupAddScoreQuery,
    FetchChallengeGroupAddScoreQueryVariables
  >(FetchChallengeGroupAddScoreDocument, options)
}
export function useFetchChallengeGroupAddScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchChallengeGroupAddScoreQuery,
    FetchChallengeGroupAddScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchChallengeGroupAddScoreQuery,
    FetchChallengeGroupAddScoreQueryVariables
  >(FetchChallengeGroupAddScoreDocument, options)
}
export type FetchChallengeGroupAddScoreQueryHookResult = ReturnType<
  typeof useFetchChallengeGroupAddScoreQuery
>
export type FetchChallengeGroupAddScoreLazyQueryHookResult = ReturnType<
  typeof useFetchChallengeGroupAddScoreLazyQuery
>
export type FetchChallengeGroupAddScoreQueryResult = Apollo.QueryResult<
  FetchChallengeGroupAddScoreQuery,
  FetchChallengeGroupAddScoreQueryVariables
>
export const FetchRenewingChallengeSeriesDocument = gql`
  query FetchRenewingChallengeSeries {
    series_missing {
      challengeGroup {
        id
        userId
        scoreType
        active_state
        title
        description
        challenges {
          active_state
          title
          description
        }
        startDate
        endDate
      }
      id
      frequency
      series
    }
  }
`

/**
 * __useFetchRenewingChallengeSeriesQuery__
 *
 * To run a query within a React component, call `useFetchRenewingChallengeSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRenewingChallengeSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRenewingChallengeSeriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRenewingChallengeSeriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRenewingChallengeSeriesQuery,
    FetchRenewingChallengeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchRenewingChallengeSeriesQuery,
    FetchRenewingChallengeSeriesQueryVariables
  >(FetchRenewingChallengeSeriesDocument, options)
}
export function useFetchRenewingChallengeSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRenewingChallengeSeriesQuery,
    FetchRenewingChallengeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchRenewingChallengeSeriesQuery,
    FetchRenewingChallengeSeriesQueryVariables
  >(FetchRenewingChallengeSeriesDocument, options)
}
export type FetchRenewingChallengeSeriesQueryHookResult = ReturnType<
  typeof useFetchRenewingChallengeSeriesQuery
>
export type FetchRenewingChallengeSeriesLazyQueryHookResult = ReturnType<
  typeof useFetchRenewingChallengeSeriesLazyQuery
>
export type FetchRenewingChallengeSeriesQueryResult = Apollo.QueryResult<
  FetchRenewingChallengeSeriesQuery,
  FetchRenewingChallengeSeriesQueryVariables
>
export const FetchAdditionalDetailsForAnEntryDocument = gql`
  query FetchAdditionalDetailsForAnEntry(
    $entryId: uuid!
    $userId: uuid
    $anonUsername: String
    $challengeId: uuid!
  ) {
    challenge_entry_by_pk(id: $entryId) {
      user {
        id
        username
        identity {
          name
        }
      }
      comment
      entryComments {
        ...ChallengeComment
      }
      score
      created_at
      challenge {
        title
        challengeGroup {
          title
          user {
            id
          }
        }
      }
      anonUsername
    }
    challenge_entry(
      where: {
        _and: {
          _or: { userId: { _eq: $userId }, anonUsername: { _eq: $anonUsername } }
          challengeId: { _eq: $challengeId }
          active_state: { _eq: ACTIVE }
        }
      }
      order_by: { scoreRank: desc_nulls_last }
    ) {
      ...ChallengeEntries
    }
  }
  ${ChallengeCommentFragmentDoc}
  ${ChallengeEntriesFragmentDoc}
`

/**
 * __useFetchAdditionalDetailsForAnEntryQuery__
 *
 * To run a query within a React component, call `useFetchAdditionalDetailsForAnEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdditionalDetailsForAnEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdditionalDetailsForAnEntryQuery({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      userId: // value for 'userId'
 *      anonUsername: // value for 'anonUsername'
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useFetchAdditionalDetailsForAnEntryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAdditionalDetailsForAnEntryQuery,
    FetchAdditionalDetailsForAnEntryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchAdditionalDetailsForAnEntryQuery,
    FetchAdditionalDetailsForAnEntryQueryVariables
  >(FetchAdditionalDetailsForAnEntryDocument, options)
}
export function useFetchAdditionalDetailsForAnEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAdditionalDetailsForAnEntryQuery,
    FetchAdditionalDetailsForAnEntryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchAdditionalDetailsForAnEntryQuery,
    FetchAdditionalDetailsForAnEntryQueryVariables
  >(FetchAdditionalDetailsForAnEntryDocument, options)
}
export type FetchAdditionalDetailsForAnEntryQueryHookResult = ReturnType<
  typeof useFetchAdditionalDetailsForAnEntryQuery
>
export type FetchAdditionalDetailsForAnEntryLazyQueryHookResult = ReturnType<
  typeof useFetchAdditionalDetailsForAnEntryLazyQuery
>
export type FetchAdditionalDetailsForAnEntryQueryResult = Apollo.QueryResult<
  FetchAdditionalDetailsForAnEntryQuery,
  FetchAdditionalDetailsForAnEntryQueryVariables
>
export const FetchUsersActiveCreatedChallengesDocument = gql`
  query FetchUsersActiveCreatedChallenges($userId: uuid!) {
    user_by_pk(id: $userId) {
      challengesCreated(
        where: {
          _and: [
            { active_state: { _eq: VISIBLE } }
            { startDate: { _lte: "now()" } }
            { endDate: { _gte: "now()" } }
          ]
        }
        order_by: [{ endDate: asc_nulls_last }]
      ) {
        ...ChallengeGroupOverview
      }
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
`

/**
 * __useFetchUsersActiveCreatedChallengesQuery__
 *
 * To run a query within a React component, call `useFetchUsersActiveCreatedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersActiveCreatedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersActiveCreatedChallengesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUsersActiveCreatedChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUsersActiveCreatedChallengesQuery,
    FetchUsersActiveCreatedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUsersActiveCreatedChallengesQuery,
    FetchUsersActiveCreatedChallengesQueryVariables
  >(FetchUsersActiveCreatedChallengesDocument, options)
}
export function useFetchUsersActiveCreatedChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUsersActiveCreatedChallengesQuery,
    FetchUsersActiveCreatedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUsersActiveCreatedChallengesQuery,
    FetchUsersActiveCreatedChallengesQueryVariables
  >(FetchUsersActiveCreatedChallengesDocument, options)
}
export type FetchUsersActiveCreatedChallengesQueryHookResult = ReturnType<
  typeof useFetchUsersActiveCreatedChallengesQuery
>
export type FetchUsersActiveCreatedChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchUsersActiveCreatedChallengesLazyQuery
>
export type FetchUsersActiveCreatedChallengesQueryResult = Apollo.QueryResult<
  FetchUsersActiveCreatedChallengesQuery,
  FetchUsersActiveCreatedChallengesQueryVariables
>
export const FetchUsersPastCreatedChallengesDocument = gql`
  query FetchUsersPastCreatedChallenges($userId: uuid!) {
    user_by_pk(id: $userId) {
      challengesCreated(
        where: { _and: [{ active_state: { _eq: VISIBLE } }, { endDate: { _lt: "now()" } }] }
        order_by: [{ endDate: desc_nulls_last }]
      ) {
        ...ChallengeGroupOverview
      }
    }
  }
  ${ChallengeGroupOverviewFragmentDoc}
`

/**
 * __useFetchUsersPastCreatedChallengesQuery__
 *
 * To run a query within a React component, call `useFetchUsersPastCreatedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersPastCreatedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersPastCreatedChallengesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUsersPastCreatedChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUsersPastCreatedChallengesQuery,
    FetchUsersPastCreatedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUsersPastCreatedChallengesQuery,
    FetchUsersPastCreatedChallengesQueryVariables
  >(FetchUsersPastCreatedChallengesDocument, options)
}
export function useFetchUsersPastCreatedChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUsersPastCreatedChallengesQuery,
    FetchUsersPastCreatedChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUsersPastCreatedChallengesQuery,
    FetchUsersPastCreatedChallengesQueryVariables
  >(FetchUsersPastCreatedChallengesDocument, options)
}
export type FetchUsersPastCreatedChallengesQueryHookResult = ReturnType<
  typeof useFetchUsersPastCreatedChallengesQuery
>
export type FetchUsersPastCreatedChallengesLazyQueryHookResult = ReturnType<
  typeof useFetchUsersPastCreatedChallengesLazyQuery
>
export type FetchUsersPastCreatedChallengesQueryResult = Apollo.QueryResult<
  FetchUsersPastCreatedChallengesQuery,
  FetchUsersPastCreatedChallengesQueryVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($username: String!, $name: String!, $profilePhoto: String, $email: String) {
    insert_user_one(
      object: {
        active_state: ACTIVE
        username: $username
        type: PERSON
        identity: { data: { name: $name, profilePhoto: $profilePhoto, email: $email } }
      }
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      name: // value for 'name'
 *      profilePhoto: // value for 'profilePhoto'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  )
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const UserByOAuthIdDocument = gql`
  query UserByOAuthId($oauth_id: String!) {
    user(distinct_on: username, where: { private: { oauth_id: { _eq: $oauth_id } } }) {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useUserByOAuthIdQuery__
 *
 * To run a query within a React component, call `useUserByOAuthIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByOAuthIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByOAuthIdQuery({
 *   variables: {
 *      oauth_id: // value for 'oauth_id'
 *   },
 * });
 */
export function useUserByOAuthIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserByOAuthIdQuery, UserByOAuthIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserByOAuthIdQuery, UserByOAuthIdQueryVariables>(
    UserByOAuthIdDocument,
    options,
  )
}
export function useUserByOAuthIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByOAuthIdQuery, UserByOAuthIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserByOAuthIdQuery, UserByOAuthIdQueryVariables>(
    UserByOAuthIdDocument,
    options,
  )
}
export type UserByOAuthIdQueryHookResult = ReturnType<typeof useUserByOAuthIdQuery>
export type UserByOAuthIdLazyQueryHookResult = ReturnType<typeof useUserByOAuthIdLazyQuery>
export type UserByOAuthIdQueryResult = Apollo.QueryResult<
  UserByOAuthIdQuery,
  UserByOAuthIdQueryVariables
>
export const UsernameAvailableDocument = gql`
  query UsernameAvailable($username: String!) {
    username_available(args: { name: $username }) {
      boolean
    }
  }
`

/**
 * __useUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailableQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(
    UsernameAvailableDocument,
    options,
  )
}
export function useUsernameAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsernameAvailableQuery,
    UsernameAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(
    UsernameAvailableDocument,
    options,
  )
}
export type UsernameAvailableQueryHookResult = ReturnType<typeof useUsernameAvailableQuery>
export type UsernameAvailableLazyQueryHookResult = ReturnType<typeof useUsernameAvailableLazyQuery>
export type UsernameAvailableQueryResult = Apollo.QueryResult<
  UsernameAvailableQuery,
  UsernameAvailableQueryVariables
>
export const FetchUserByNameDocument = gql`
  query FetchUserByName($name: String!) {
    user(where: { username: { _ilike: $name } }) {
      ...UserOverview
    }
  }
  ${UserOverviewFragmentDoc}
`

/**
 * __useFetchUserByNameQuery__
 *
 * To run a query within a React component, call `useFetchUserByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFetchUserByNameQuery(
  baseOptions: Apollo.QueryHookOptions<FetchUserByNameQuery, FetchUserByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchUserByNameQuery, FetchUserByNameQueryVariables>(
    FetchUserByNameDocument,
    options,
  )
}
export function useFetchUserByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchUserByNameQuery, FetchUserByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchUserByNameQuery, FetchUserByNameQueryVariables>(
    FetchUserByNameDocument,
    options,
  )
}
export type FetchUserByNameQueryHookResult = ReturnType<typeof useFetchUserByNameQuery>
export type FetchUserByNameLazyQueryHookResult = ReturnType<typeof useFetchUserByNameLazyQuery>
export type FetchUserByNameQueryResult = Apollo.QueryResult<
  FetchUserByNameQuery,
  FetchUserByNameQueryVariables
>
export const FetchUserProfilePageDetailsDocument = gql`
  query FetchUserProfilePageDetails($userId: uuid!) {
    user_by_pk(id: $userId) {
      id
      username
      active_state
      type
      identity {
        name
        profilePhoto
      }
      challengesCreated_aggregate(
        where: { _and: [{ active_state: { _eq: VISIBLE } }, { startDate: { _lte: "now()" } }] }
      ) {
        aggregate {
          count
        }
      }
      challengeEntries_aggregate {
        aggregate {
          differentChallenges: count(columns: challengeId, distinct: true)
          totalEntries: count
        }
      }
    }
  }
`

/**
 * __useFetchUserProfilePageDetailsQuery__
 *
 * To run a query within a React component, call `useFetchUserProfilePageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserProfilePageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserProfilePageDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUserProfilePageDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUserProfilePageDetailsQuery,
    FetchUserProfilePageDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUserProfilePageDetailsQuery,
    FetchUserProfilePageDetailsQueryVariables
  >(FetchUserProfilePageDetailsDocument, options)
}
export function useFetchUserProfilePageDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserProfilePageDetailsQuery,
    FetchUserProfilePageDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUserProfilePageDetailsQuery,
    FetchUserProfilePageDetailsQueryVariables
  >(FetchUserProfilePageDetailsDocument, options)
}
export type FetchUserProfilePageDetailsQueryHookResult = ReturnType<
  typeof useFetchUserProfilePageDetailsQuery
>
export type FetchUserProfilePageDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchUserProfilePageDetailsLazyQuery
>
export type FetchUserProfilePageDetailsQueryResult = Apollo.QueryResult<
  FetchUserProfilePageDetailsQuery,
  FetchUserProfilePageDetailsQueryVariables
>
export const FetchUsersEntriesDocument = gql`
  query FetchUsersEntries($userId: uuid!) {
    user_by_pk(id: $userId) {
      challengeEntries(
        where: { active_state: { _eq: ACTIVE } }
        order_by: [{ created_at: desc_nulls_last }]
      ) {
        id
        score
        created_at
        challenge {
          challengeGroup {
            id
            title
            user {
              id
              username
            }
          }
        }
      }
    }
  }
`

/**
 * __useFetchUsersEntriesQuery__
 *
 * To run a query within a React component, call `useFetchUsersEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersEntriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUsersEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchUsersEntriesQuery, FetchUsersEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchUsersEntriesQuery, FetchUsersEntriesQueryVariables>(
    FetchUsersEntriesDocument,
    options,
  )
}
export function useFetchUsersEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUsersEntriesQuery,
    FetchUsersEntriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchUsersEntriesQuery, FetchUsersEntriesQueryVariables>(
    FetchUsersEntriesDocument,
    options,
  )
}
export type FetchUsersEntriesQueryHookResult = ReturnType<typeof useFetchUsersEntriesQuery>
export type FetchUsersEntriesLazyQueryHookResult = ReturnType<typeof useFetchUsersEntriesLazyQuery>
export type FetchUsersEntriesQueryResult = Apollo.QueryResult<
  FetchUsersEntriesQuery,
  FetchUsersEntriesQueryVariables
>

import 'pattern.css'

import type { AppProps } from 'next/app'
import { ClientSafeProvider, getProviders, SessionProvider } from 'next-auth/react'
import PlausibleProvider from 'next-plausible'
import React, { Fragment, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { GlobalStyles } from 'twin.macro'
// @ts-expect-error(arlyon): no types for this item
import createPersistedReducer from 'use-persisted-reducer'

import {
  challengeGroupReducer,
  EditChallengeContext,
  INITIAL_STORE as challengeInitialStore,
} from '@/domain/challenge/EditChallengeContext'
import { ApolloProvider } from '@/domain/common/ApolloProvider'
import { OnboardingContext, onboardingReducer } from '@/domain/common/OnboardingContext'
import { OnlineStatusProvider } from '@/domain/common/OnlineStatusProvider'
import { AuthProviders } from '@/domain/common/useProviders'
import { SignInProvider } from '@/domain/common/useSignIn'

import { NextPage } from '../types/next'

type Props = AppProps & {
  Component: NextPage
}

const useCGReducer = createPersistedReducer('new_challenge')
const useOBReducer = createPersistedReducer('onboarding')

const App = ({ Component, pageProps }: Props) => {
  const getLayout = Component.getLayout || ((page) => page)
  const Layout = Component.layout ?? Fragment

  const challengeGroup = useCGReducer(challengeGroupReducer, challengeInitialStore)
  const onboarding = useOBReducer(onboardingReducer, {})
  const [authProviders, setAuthProviders] = useState<Record<string, ClientSafeProvider>>({})

  useEffect(() => {
    ;(async () => setAuthProviders((await getProviders()) ?? {}))()
  }, [])

  return (
    <PlausibleProvider domain="social.fitness">
      <SessionProvider session={pageProps.session}>
        <ApolloProvider>
          <SignInProvider>
            <AuthProviders.Provider value={authProviders}>
              <EditChallengeContext.Provider value={challengeGroup}>
                <OnboardingContext.Provider value={onboarding}>
                  <OnlineStatusProvider>
                    <GlobalStyles />
                    <Layout>
                      {getLayout(<Component {...pageProps} />)}
                      <Toaster containerStyle={{ marginTop: 50 }} />
                    </Layout>
                  </OnlineStatusProvider>
                </OnboardingContext.Provider>
              </EditChallengeContext.Provider>
            </AuthProviders.Provider>
          </SignInProvider>
        </ApolloProvider>
      </SessionProvider>
    </PlausibleProvider>
  )
}

export default App

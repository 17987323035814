import React from 'react'

type StoreType = {
  searchComplete: boolean
  addedScore: boolean
  challengeCreated: boolean
}

export type EditAction =
  | {
      type: 'update'
      payload: Partial<StoreType>
    }
  | {
      type: 'overwrite'
      payload: StoreType
    }

export const onboardingReducer = (state: StoreType, action: EditAction) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'overwrite':
      return action.payload
  }
}

export const OnboardingContext = React.createContext<[StoreType, React.Dispatch<EditAction>]>([
  { searchComplete: false, addedScore: false, challengeCreated: false },
  () => undefined,
])

import { createContext, FC, useContext, useState } from 'react'

const defaultData = {
  listeners: [] as { onComplete: (x: any) => void; onCancel: () => void }[],
  open: false,
}

const SignInContext = createContext([defaultData, () => {}] as [
  typeof defaultData,
  (value: typeof defaultData) => void,
])

export const SignInProvider: FC = ({ children }) => {
  const signIn = useState(defaultData)
  return <SignInContext.Provider value={signIn}>{children}</SignInContext.Provider>
}

export const useSignIn = () => {
  const [ctx, setCtx] = useContext(SignInContext)

  const signIn = async () => {
    return await new Promise((resolve, reject) => {
      setCtx({
        open: true,
        listeners: [...ctx.listeners, { onComplete: resolve, onCancel: reject }],
      })
    })
  }

  const cancel = () => {
    for (const listener of ctx.listeners) {
      listener.onCancel()
    }
    setCtx({ open: false, listeners: [] })
  }

  const complete = () => {
    for (const listener of ctx.listeners) {
      listener.onComplete(null)
    }
    setCtx({ open: false, listeners: [] })
  }

  return {
    signIn,
    open: ctx.open,
    cancel,
    complete,
  }
}

import React from 'react'

import {
  Challenge,
  Challenge_Group,
  Challenge_Group_Active_State_Enum_Enum,
  Challenge_Group_Series_Frequency_Enum_Enum,
} from '@/graphql/types'

import type { Overwrite } from '../common/Overwrite'
import { ScoreType } from './ScoreEntry'

type StoreType = Partial<
  Overwrite<
    Challenge_Group,
    { challenges: Partial<Challenge>[]; frequency: Challenge_Group_Series_Frequency_Enum_Enum }
  >
>
const createTomorrow = (): Date => {
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  return tomorrow
}

export const INITIAL_STORE: StoreType = {
  scoreType: ScoreType.WEIGHT,
  active_state: Challenge_Group_Active_State_Enum_Enum.Visible,
  title: '',
  description: '',
  challenges: [],
  frequency: Challenge_Group_Series_Frequency_Enum_Enum.OneOff,
  startDate: new Date().toISOString(),
  endDate: createTomorrow().toISOString(),
}

export type EditAction =
  | {
      type: 'update'
      payload: StoreType
    }
  | {
      type: 'overwrite'
      payload: StoreType
    }

export const endDates = {
  DAILY: (startDate: Date) =>
    new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1),
  WEEKLY: (startDate: Date) =>
    new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7),
  MONTHLY: (startDate: Date) =>
    new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()),
  ONE_OFF: (startDate: Date, days = 1) =>
    new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + days),
}

export const challengeGroupReducer = (state: StoreType, action: EditAction) => {
  const startDate = action.payload.startDate ?? state.startDate
  const frequency = action.payload.frequency ?? state.frequency

  if (
    startDate !== undefined &&
    frequency !== undefined &&
    action.payload.endDate === undefined &&
    (action.payload.startDate !== state.startDate || action.payload.frequency !== state.frequency)
  ) {
    action.payload.endDate = endDates[frequency](new Date(startDate)).toISOString()
  }

  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'overwrite':
      return action.payload
  }
}

export const EditChallengeContext = React.createContext<[StoreType, React.Dispatch<EditAction>]>([
  {},
  () => undefined,
])

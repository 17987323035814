import React, { useCallback, useContext, useEffect, useState } from 'react'

const OnlineStatusContext = React.createContext(true)

export const OnlineStatusProvider: React.FC = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

  const online = useCallback(() => setOnlineStatus(true), [setOnlineStatus])
  const offline = useCallback(() => setOnlineStatus(false), [setOnlineStatus])

  useEffect(() => {
    window.addEventListener('online', online)
    window.addEventListener('offline', offline)
    return () => {
      window.removeEventListener('online', online)
      window.removeEventListener('offline', offline)
    }
  }, [])

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext)
  return store
}
